import { UUID } from "crypto";
import { baseApi } from "./baseApi";
import {
  ContractDoc,
  ContractDocsResponse,
  DocumentInfo,
  DocumentsResponse,
  PayloadContractDoc,
  PayloadDocument,
  PayloadPatchContractDoc,
  PayloadSignUrl,
  PayloadUploadFile,
  SignedUrlFileResponse,
  UrlDownloadDocument,
} from "@interfaces/documents";
import { ResponseBase } from "@interfaces/response";

const documentsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getDocumentsByApp: build.query<
      DocumentsResponse,
      { applicationId: UUID | undefined; type: "company" | "shareholders_owners" }
    >({
      query: ({ applicationId, type }) => ({
        url: `solicitudes/${applicationId}/bpm/documents`,
        params: {
          q: `type:${type}`,
        },
      }),
    }),
    getDocuments: build.query<Array<DocumentInfo>, {}>({
      query: (params) => ({
        url: `documentos`,
        params: params,
      }),
      transformResponse: (response: ResponseBase) => {
        return response.data;
      },
    }),
    patchDocument: build.mutation<any, { applicationId: UUID | undefined; documentId: UUID; body: {} }>({
      query: ({ documentId, body }) => ({
        url: `documentos/${documentId}`,
        method: "PATCH",
        body: body,
      }),
    }),
    deleteDocument: build.mutation<any, UUID>({
      query: (documentId) => ({
        url: `documentos/${documentId}`,
        method: "DELETE",
      }),
    }),
    postDocument: build.mutation<any, PayloadDocument>({
      query: (data: PayloadDocument) => ({
        url: "documentos",
        method: "POST",
        body: data,
      }),
    }),
    postDocumentReasonReject: build.mutation<any, { documentId: UUID; body: unknown }>({
      query: ({ documentId, body }) => ({
        url: `documentos/${documentId}/razones_rechazo`,
        method: "POST",
        body,
      }),
    }),
    getUrlToDownloadDoc: build.mutation<string, string>({
      query: (fileKey) => ({
        url: `generar_url/descargar`,
        method: "POST",
        body: {
          file_key: fileKey,
        },
      }),
      transformResponse: (response: UrlDownloadDocument) => {
        return response.data;
      },
    }),
    getSignUrlToUploadDoc: build.query<SignedUrlFileResponse, PayloadSignUrl>({
      query: (body) => ({
        url: `generar_url/subir`,
        method: "POST",
        body,
      }),
    }),
    postUploadFile: build.mutation<Response, PayloadUploadFile>({
      query: (data: PayloadUploadFile) => ({
        url: data.url,
        method: "POST",
        body: data.body,
        formData: true,
      }),
    }),
    getContractDocs: build.query<Array<DocumentInfo>, { applicationId: UUID; clientDetailsId: UUID }>({
      query: ({ applicationId, clientDetailsId }) => ({
        url: `documentos/documents_contract?q=loan_application_id:${applicationId}&q=client_details_id:${clientDetailsId}`,
      }),
      transformResponse: (response: ResponseBase) => {
        return response.data.data.map((doc: ContractDoc) => {
          const tmpDoc: DocumentInfo = {
            id: doc.id,
            client_details_id: doc.client_details_id,
            url: doc.url,
            created_at: "",
            updated_at: "",
            category_code: doc.file_category_code,
            category_name: doc.file_category.name,
            file_category_id: doc.file_category_id,
            file_name: doc.file_name,
            status: true,
            validated: doc.validated,
            sign_client: doc.sign_client,
            sign_user: doc.sign_user,
            sign_validated: doc.sign_validated,
          };
          return tmpDoc;
        });
      },
    }),
    postContractDoc: build.mutation<Response, PayloadContractDoc>({
      query: (data: PayloadContractDoc) => ({
        url: "documentos/documents_contract",
        method: "POST",
        body: data,
      }),
    }),
    patchContractDoc: build.mutation<Response, {contractDocId: UUID; payload: PayloadPatchContractDoc}>({
      query: ({contractDocId, payload}) => ({
        url: `documentos/documents_contract/${contractDocId}`,
        method: 'PATCH',
        body: payload
      })
    })
  }),
});

export const {
  useGetDocumentsByAppQuery,
  useGetDocumentsQuery,
  useGetContractDocsQuery,
  useLazyGetContractDocsQuery,
  useLazyGetDocumentsQuery,
  useLazyGetDocumentsByAppQuery,
  usePatchDocumentMutation,
  useDeleteDocumentMutation,
  usePostDocumentReasonRejectMutation,
  useGetUrlToDownloadDocMutation,
  useLazyGetSignUrlToUploadDocQuery,
  usePostUploadFileMutation,
  usePostDocumentMutation,
  usePostContractDocMutation,
  usePatchContractDocMutation
} = documentsApi;
