import { ReactNode, useEffect, useState } from "react";
import { Box, Grid, Grid2, Link, Typography } from "@mui/material";

import "dayjs/locale/es";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "@store/store";

import { CustomModal } from "@components/CustomModal";
import { CompanyFileTab } from "@components/CompanyFileTab";
import { useLazyGetStatusQuery } from "@services/api/catalogs";
import { setShowValidateFileModal } from "@store/slices/appSlice";
import { CustomTabPanel, CustomTabs } from "@components/CustomTabs";
import { GuaranteeOwnerFileTab } from "@components/GuaranteeOwnerFileTab";
import { ValidateDialogSegment } from "@components/ValidateDialogSegment";
import { usePutApplicationsMutation } from "@services/api/applications";
import { useLazyGetDocumentsQuery, usePatchDocumentMutation } from "@services/api/documents";

export const Expediente = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [childrenModal, setChildrenModal] = useState<string>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const validateFileModal = useSelector((state: RootState) => state.app.validateFileModal);
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const [triggerGetStatus] = useLazyGetStatusQuery();
  const [triggerGetDocuments] = useLazyGetDocumentsQuery();
  const [triggerPutApplication] = usePutApplicationsMutation();
  const [trigetPatchDocument] = usePatchDocumentMutation();

  const handleOnClickBack = () => {
    navigate("/");
  };

  const handleOnChangeTab = (_: string, index: number) => {
    setCurrentTab(index);
  };

  const handleOnModalBackClicked = () => {
    dispatch(setShowValidateFileModal(false));
  };

  const handleModalValidateClicked = async () => {
    setIsProcessing(true);
    const statusCatalog = await triggerGetStatus();
    const statusToReturn = statusCatalog?.data?.data.data.find((item) => item.code === "CSEDV");

    await triggerPutApplication({
      applicationId: applicationSelected.id,
      body: {
        status_id: statusToReturn?.id,
      },
    });

    const docs = await triggerGetDocuments({
      loan_application_id: applicationSelected.id,
    });

    const docsNotValidated = docs.data
      ?.filter((doc) => doc.status && doc.validated === false)
      .map((doc) => {
        return trigetPatchDocument({
          applicationId: applicationSelected.id,
          documentId: doc.id ?? "0-0-0-0-0",
          body: {
            validated: true,
          },
        });
      });

    if (docsNotValidated !== undefined) {
      Promise.all(docsNotValidated);
    }
    setIsProcessing(false);
    dispatch(setShowValidateFileModal(false));
  };

  useEffect(() => {
    if (validateFileModal.show) {
      setChildrenModal("VALIDATE");
    }
  }, [validateFileModal.show]);

  useEffect(() => {
    if (
      applicationSelected.company_docs_validated &&
      applicationSelected.shareholder_docs_validated &&
      applicationSelected.owners_docs_validated
    ) {
      setChildrenModal("VALIDATE");
    }
  }, [
    applicationSelected.company_docs_validated,
    applicationSelected.shareholder_docs_validated,
    applicationSelected.owners_docs_validated,
  ]);

  return (
    <>
      <Box marginTop={1}>
        <Grid2 container>
          <Grid2>
            <Link underline="none" onClick={handleOnClickBack}>
              <Typography color="#F2704F" fontWeight={600} my={2}>
                Regresar a solicitudes
              </Typography>
            </Link>
          </Grid2>
        </Grid2>

        <Typography variant="h4" my={1}>
          {applicationSelected.razonSocial}
        </Typography>
        <Typography variant="h4" mt={1} mb={4}>
          ID SOLICITUD:{" "}
          <Typography component={"span"} variant="h4" fontWeight={600}>
            {applicationSelected.solicitudId}
          </Typography>
        </Typography>
        <Box px={10}>
          <CustomTabs
            titleTabs={["Expediente empresa", "Expediente aval y propietarios"]}
            onChangeTab={handleOnChangeTab}
          />
          <CustomTabPanel index={0} value={currentTab} alias="normal">
            <Box flexGrow={1} display={currentTab == 0 ? "flex" : "none"}>
              <CompanyFileTab />
            </Box>
          </CustomTabPanel>
          <CustomTabPanel index={1} value={currentTab} alias="normal">
            <Box flexGrow={1} display={currentTab == 1 ? "flex" : "none"}>
              <GuaranteeOwnerFileTab />
            </Box>
          </CustomTabPanel>
        </Box>
      </Box>
      <CustomModal open={validateFileModal.show}>
        {childrenModal === "VALIDATE" && (
          <ValidateDialogSegment
            type={"DOCVALIDATE"}
            isProcessing={isProcessing}
            title={`Estas seguro validar el expediente de:`}
            onBackClick={handleOnModalBackClicked}
            onContinueClick={handleModalValidateClicked}
          />
        )}
      </CustomModal>
    </>
  );
};
