import { useEffect, useMemo, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "@store/store";
import { OnSiteVisit } from "./OnSiteVisit";
import { CustomModal } from "./CustomModal";
import { CustomTabPanel } from "./CustomTabs";
import { generateDummyDocuments, getMimeTypeFromFilename } from "@helpers/generals";
import { CustomVerticalTabPanel } from "./CustomVerticalTabs";
import { RejectDocumentDialogSegment } from "./RejectDocumentDialogSegment";
import {
  useGetUrlToDownloadDocMutation,
  useLazyGetDocumentsByAppQuery,
  useLazyGetSignUrlToUploadDocQuery,
  usePatchDocumentMutation,
  usePostDocumentMutation,
  usePostUploadFileMutation,
} from "@services/api/documents";
// import { DocumentAction, DocumentSection, FileSection, FileSectionHeaders, SignedAction } from "./FileSection";
import { DocumentAction, FileSectionV2 } from "./FileSectionV2";
import { ValidateDialogSegment } from "./ValidateDialogSegment";
import { useGetFileCategoriesQuery, useLazyGetStatusQuery } from "@services/api/catalogs";
import { usePutApplicationsMutation } from "@services/api/applications";
import { setCompanyDocsValidated, setShowValidateFileModal } from "@store/slices/appSlice";
import { ContractDocsTab } from "./CompanyFileTabSegments/ContractDocsTab";
import { DocumentInfo } from "@interfaces/documents";
import { GeneralTab } from "./CompanyFileTabSegments/GeneralTab";

export const CompanyFileTab = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [checkForChange, setCheckForChange] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [childrenModal, setChildrenModal] = useState<"VALIDATE" | "REJECT" | "VALIDATEVISIT">();
  const [currentVerticalTab, setCurrentVerticalTab] = useState<number>(0);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [documentTriggered, setDocumentTriggered] = useState<DocumentInfo>();
  const [documentActionTriggered, setDocumentActionTriggered] = useState<DocumentAction>();
  const [enableValidateVisit, setEnableValidateVisit] = useState<boolean>(false);
  const [companyTabs, setCompanyTabs] = useState<Array<any>>([
    { title: "General", active: true },
    { title: "Expediente", active: true },
    { title: "Dictamen", active: true },
    { title: "Visita Ocular", active: false },
    { title: "Contractuales", active: false },
    { title: "Resumen crédito", active: false },
  ]);

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const [triggerGetDocuments, resultGetDocuments] = useLazyGetDocumentsByAppQuery({
    selectFromResult: ({ data }) => ({
      documents: data?.data?.company,
    }),
  });

  const documenCategories = useGetFileCategoriesQuery();

  const [triggerGetStatus] = useLazyGetStatusQuery();
  const [triggerPostDocument] = usePostDocumentMutation();
  const [triggerPatchDocument] = usePatchDocumentMutation();
  const [triggerPostUploadDoc] = usePostUploadFileMutation();
  const [triggerPutApplication] = usePutApplicationsMutation();
  const [triggerPatchApplication] = usePutApplicationsMutation();
  const [triggerPostUrlDocument] = useGetUrlToDownloadDocMutation();
  const [triggetPostUrlToUploadDoc] = useLazyGetSignUrlToUploadDocQuery();

  const handleOnChangeVerticalTab = (_: string, index: number) => {
    setCurrentVerticalTab(index);
  };

  const handleOnModalBackClicked = () => {
    setShowConfirmModal(false);
  };

  const handleOnModalConfirmClicked = async () => {
    setIsProcessing(true);
    if (documentActionTriggered === DocumentAction.REJECT) {
      const statusCatalog = await triggerGetStatus();
      const currentStatusId = JSON.parse(applicationSelected.fullData)["status_id"];
      const currentStatusCode = statusCatalog?.data?.data.data.find((item) => item.id === currentStatusId)?.code;

      setCheckForChange(true);

      if (currentStatusCode === "CSEDC") {
        const statusToReturn = statusCatalog?.data?.data.data.find((item) => item.code === "CSECI");
        await triggerPatchApplication({
          applicationId: applicationSelected.id,
          body: {
            status_id: statusToReturn?.id,
          },
        });
      }

      const loan_application_completed = ["DCD"].find((doc) => doc === documentTriggered?.category_code) ? false : null;
      const bank_information_completed = ["DECB"].find((doc) => doc === documentTriggered?.category_code)
        ? false
        : null;

      if (loan_application_completed === false || bank_information_completed === false) {
        await triggerPatchApplication({
          applicationId: applicationSelected.id,
          body: {
            loan_application_completed,
            bank_information_completed,
          },
        });

        await triggerPutApplication({
          applicationId: applicationSelected.id,
          body: {
            completed: false,
          },
        });
      }

      await triggerGetDocuments({ applicationId: applicationSelected.id, type: "company" });
    } else if (documentActionTriggered === DocumentAction.VALIDATE) {
      setCheckForChange(true);
      try {
        await triggerPatchDocument({
          applicationId: applicationSelected.id,
          documentId: documentTriggered?.id ?? "0-0-0-0-0",
          body: {
            validated: true,
          },
        }).unwrap();

        await triggerGetDocuments({ applicationId: applicationSelected.id, type: "company" });
      } catch (error) {
        console.log("Error al validar documento: %s", error);
      }
    }
    setShowConfirmModal(false);
    setIsProcessing(false);
  };

  const handleOnFileActionClicked = async (
    action: DocumentAction,
    file: DocumentInfo,
    newFile?: EventTarget & HTMLInputElement,
  ) => {
    if (action === DocumentAction.SEE) {
      const urlDownload = await triggerPostUrlDocument(file.url);

      const response = await fetch(urlDownload.data ?? "");
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob], { type: getMimeTypeFromFilename(file.file_name) }));
      window.open(url);
    } else if (action === DocumentAction.DOWNLOAD) {
      const urlDownload = await triggerPostUrlDocument(file.url);
      const response = await fetch(urlDownload.data ?? "");
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("target", "_blank");
      link.setAttribute("download", file.file_name);

      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } else if (action === DocumentAction.UPLOAD) {
      const tmpFile = newFile?.files?.[0];
      try {
        if (file.id === undefined) {
          file.client_details_id = JSON.parse(applicationSelected.fullData)["client_details_id"];
        }

        const urlToUploadResponse = await triggetPostUrlToUploadDoc({
          upload_type: "client",
          file_name: tmpFile?.name ?? "",
          regimen: "pm",
          client_details_id: file.client_details_id,
          file_category_id: file.file_category_id,
          loan_application_id: applicationSelected.id,
        }).unwrap();

        const signUrl = urlToUploadResponse.data;

        const tmp = new FormData();

        tmp.append("key", signUrl.fields.key);
        tmp.append("AWSAccessKeyId", signUrl.fields.AWSAccessKeyId);
        tmp.append("x-amz-security-token", signUrl.fields["x-amz-security-token"]);
        tmp.append("policy", signUrl.fields.policy);
        tmp.append("signature", signUrl.fields.signature);
        tmp.append("file", tmpFile);

        await triggerPostUploadDoc({
          url: signUrl.url,
          body: tmp,
        }).unwrap();

        if (file.id !== undefined) {
          await triggerPatchDocument({
            applicationId: applicationSelected.id,
            documentId: file.id,
            body: {
              status: false,
            },
          }).unwrap();
        }

        await triggerPostDocument({
          loan_application_id: applicationSelected.id ?? "0-0-0-0-0",
          file_category_id: file.file_category_id ?? "0-0-0-0-0",
          url: signUrl.fields.key,
          file_name: tmpFile?.name ?? "",
          client_details_id: file.client_details_id,
        }).unwrap();
      } catch (error) {
      } finally {
        tmpFile.value = "";
        await triggerGetDocuments({ applicationId: applicationSelected.id, type: "company" });
      }
    } else if (action === DocumentAction.VALIDATE) {
      setDocumentActionTriggered(action);
      setDocumentTriggered(file);
      setChildrenModal("VALIDATE");
      setShowConfirmModal(true);
    } else if (action === DocumentAction.REJECT) {
      setDocumentActionTriggered(action);
      setDocumentTriggered(file);
      setChildrenModal("REJECT");
      setShowConfirmModal(true);
    }
  };

  const handleOnModalConfirmValidateVisitClicked = async () => {
    try {
      const statusCatalog = await triggerGetStatus();

      await triggerPatchApplication({
        applicationId: applicationSelected.id,
        body: {
          status_id: statusCatalog?.data?.data.data.find((item) => item.code === "CSEVD")?.id,
        },
      }).unwrap();
    } catch (error) {
      console.log("Error - no se pudo actualizar el estatus de la solicitud: %s", error);
    }
  };

  const handleOnToGoClicked = async () => {
    navigate("/dictamen");
  };

  const handleValidateClicked = () => {
    dispatch(setShowValidateFileModal(true));
  };

  const handleVisitValidateClicked = () => {
    setChildrenModal("VALIDATEVISIT");
    setShowConfirmModal(true);
  };

  const disableGoToDictamen = useMemo(() => {
    if (!resultGetDocuments) return true;

    const result = ["DIORL", "DAC"]
      .map((code) => resultGetDocuments.documents?.grals.some((item) => item.category_code === code))
      .every((flag) => flag === true);

    return !result;
  }, [resultGetDocuments]);

  useEffect(() => {
    const docsToValidated = [];
    const checkValidateFolder = [];
    if (applicationSelected.tax_systems.code === "CS002") {
      //PFAE
      docsToValidated.push("DCD", "SDC"); //Se remueve la constancia de situación fiscal -> DCDF
      checkValidateFolder.push("DCD", "SDC"); //Se remueve la constancia de situación fiscal -> DCDF
    } else {
      docsToValidated.push("DCD", "SDC", "DIORL", "DAC", "CDDTN"); //Se remueve la constancia de situación fiscal -> DCDF
      checkValidateFolder.push("DCD", "SDC"); //Se remueve la constancia de situación fiscal -> DCDF
    }

    const isCompletedFolder =
      checkValidateFolder
        .map((code) => resultGetDocuments.documents?.grals.some((doc) => doc.category_code == code && doc.validated))
        .every((flag) => flag === true) ?? false;

    const result =
      docsToValidated
        .map((code) => resultGetDocuments.documents?.grals.some((doc) => doc.category_code == code && doc.validated))
        .every((flag) => flag === true) ?? false;

    const docs_accounts_validated =
      resultGetDocuments.documents?.accounts
        .map((account) => account.bank_statements.every((statement) => statement.validated))
        .every((t) => t === true) ?? false;

    const docs_accounts_completed =
      resultGetDocuments.documents?.accounts
        .map((account) => account.bank_statements.length === 3)
        .every((t) => t === true) ?? false;

    if (checkForChange && isCompletedFolder && docs_accounts_completed && docs_accounts_validated) {
      try {
        triggerPutApplication({
          applicationId: applicationSelected.id,
          body: {
            completed: true,
          },
        }).unwrap();
      } catch (error) {
        console.log(error);
        console.error("No se pudo cambiar el estado de la empresa a completed");
      } finally {
        setCheckForChange(false);
      }
    }

    console.log("Company:", result && docs_accounts_validated && docs_accounts_completed);
    dispatch(setCompanyDocsValidated(result && docs_accounts_validated && docs_accounts_completed));

    setEnableValidateVisit(
      resultGetDocuments.documents?.grals.find((doc) => doc.category_code === "CDVOC")?.validated ?? false,
    );
  }, [resultGetDocuments]);

  useEffect(() => {
    triggerGetDocuments({ applicationId: applicationSelected.id, type: "company" });

    triggerGetStatus().then((values) => {
      const currentStatusId = JSON.parse(applicationSelected.fullData)["status_id"];
      const currentStatusCode = values.data?.data?.data?.find((item) => item.id === currentStatusId)?.code;

      if (
        ["CSEDV", "CSAVC", "CSVYC", "CSIYA", "CSREC", "CSRDO", "CSFIR", "CSEVD"].find(
          (item) => item === currentStatusCode,
        )
      ) {
        setCompanyTabs([
          { title: "General", active: true },
          { title: "Expediente", active: true },
          { title: "Dictamen", active: true },
          { title: "Visita Ocular", active: true },
          { title: "Contractuales", active: true },
          { title: "Resumen crédito", active: true },
        ]);
      }
    });
  }, []);

  return (
    <>
      <CustomVerticalTabPanel
        titleTabs={companyTabs}
        alias="vertical"
        sx={{ borderRight: 1, borderColor: "#A3D4E8" }}
        onChangeTab={handleOnChangeVerticalTab}
      />
      <CustomTabPanel alias="vertical" width={"100%"} index={0} value={currentVerticalTab}>
        <Box margin={3} display={0 == currentVerticalTab ? "block" : "none"}>
          <GeneralTab />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel alias="vertical" width={"100%"} index={1} value={currentVerticalTab}>
        <Box margin={3} display={1 == currentVerticalTab ? "block" : "none"}>
          <Typography variant="h4" fontWeight={600} color={"#002652"}>
            Expediente
          </Typography>
          <FileSectionV2
            showHeaders
            title="Comprobante de domicilio"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "DCD")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "DCD")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "DCD"),
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
          />
          <FileSectionV2
            showHeaders
            title="Comprobante de domicilio confirmado"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "DCDC")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "DCDC")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "DCDC"),
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
          />
          <FileSectionV2
            title="Solicitud de crédito"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "SDC")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "SDC")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "SDC"),
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
          />
          <FileSectionV2
            title="Constacia de situación fiscal"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "DCDF")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "DCDF")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "DCDF"),
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
          />
          {resultGetDocuments?.documents?.accounts.map((account, index) => (
            <FileSectionV2
              key={index}
              title="Información bancaria"
              extras={[
                { title: "Cuenta CLABE", value: account.clabe },
                { title: "Banco", value: account.bank_name },
              ]}
              documents={account.bank_statements}
              onFileActionClick={handleOnFileActionClicked}
            />
          ))}

          <FileSectionV2
            title="Visita ocular"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "CDVOC")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "CDVOC")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "CDVOC"),
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
          />
        </Box>
        <Box mt={10} display={"flex"} justifyContent={"flex-end"} columnGap={2}>
          <Button variant="zomp" onClick={handleVisitValidateClicked}>
            Validar visita
          </Button>
          <Button
            variant="zomp"
            disabled={
              (applicationSelected.tax_systems.code === "CS001" &&
                (applicationSelected.company_docs_validated == false ||
                  applicationSelected.shareholder_docs_validated == false ||
                  applicationSelected.owners_docs_validated == false)) ||
              (applicationSelected.tax_systems.code === "CS002" && applicationSelected.company_docs_validated == false)
            }
            onClick={handleValidateClicked}
          >
            Validar expediente
          </Button>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel alias="vertical" width={"100%"} index={2} value={currentVerticalTab}>
        <Box margin={3} display={2 == currentVerticalTab ? "block" : "none"}>
          <Typography variant="h4" fontWeight={600} color={"#002652"}>
            Dictamen
          </Typography>
          <FileSectionV2
            title="Identificación representante legal"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "DIORL")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "DIORL")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "DIORL"),
                  )
            }
            showHeaders
            onFileActionClick={handleOnFileActionClicked}
          />
          <FileSectionV2
            title="Acta constitutiva"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "DAC")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "DAC")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "DAC"),
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
          />
          <FileSectionV2
            title="Actas adicionales"
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "DAA")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "DAA")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "DAA"),
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
          />
          <Typography variant="h4" fontWeight={600} color={"#002652"} pt={4}>
            Módulo Dictamen
          </Typography>
          <FileSectionV2
            title=""
            documents={
              resultGetDocuments?.documents?.grals.some((item) => item.category_code === "CDDTN")
                ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "CDDTN")
                : generateDummyDocuments(
                    1,
                    documenCategories.currentData?.find((category) => category.code === "CDDTN"),
                  )
            }
            onFileActionClick={handleOnFileActionClicked}
            onToGoClick={handleOnToGoClicked}
            showButtonGo
            showHeaders
          />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel alias="vertical" width={"100%"} index={3} value={currentVerticalTab}>
        <OnSiteVisit
          applicationId={applicationSelected.id}
          clientDetailsId={JSON.parse(applicationSelected.fullData)["client_details_id"]}
        />
      </CustomTabPanel>
      <CustomTabPanel alias="vertical" width={"100%"} index={4} value={currentVerticalTab}>
        <Box display={4 == currentVerticalTab ? "block" : "none"}>
          <ContractDocsTab />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel alias="vertical" width={"100%"} index={5} value={currentVerticalTab}>
        <Box margin={3}>
          <Typography variant="h4" fontWeight={600} color={"#002652"}>
            Resumen crédito
          </Typography>
          <Stack rowGap={2} mt={2}>
            <Typography variant="h4" color={"#002652"}>
              No. de cliente:{" "}
              <Typography component={"span"} variant="h4" color={"#528CD6"} fontWeight={"400"}>
                0000001
              </Typography>{" "}
            </Typography>

            <Typography variant="h4" color={"#002652"}>
              No. de contrato:{" "}
              <Typography component={"span"} variant="h4" color={"#528CD6"} fontWeight={"400"}>
                XXXXXXXXXX-X
              </Typography>{" "}
            </Typography>

            <Typography variant="h4" color={"#002652"}>
              Cuenta CLABE de Deposito:{" "}
              <Typography component={"span"} variant="h4" color={"#528CD6"} fontWeight={"400"}>
                XXXX XXXX XXXX XXXX XX
              </Typography>{" "}
            </Typography>

            <Typography variant="h4" color={"#002652"}>
              Monto del crédito:{" "}
              <Typography component={"span"} variant="h4" color={"#528CD6"} fontWeight={"400"}>
                $X,XXX,XXX
              </Typography>{" "}
            </Typography>

            <Typography variant="h4" color={"#002652"}>
              Plazo del crédito:{" "}
              <Typography component={"span"} variant="h4" color={"#528CD6"} fontWeight={"400"}>
                XX meses
              </Typography>{" "}
            </Typography>

            <Typography variant="h4" color={"#002652"}>
              Fecha de dispersión:{" "}
              <Typography component={"span"} variant="h4" color={"#528CD6"} fontWeight={"400"}>
                XX/XXX/XXXX
              </Typography>{" "}
            </Typography>
          </Stack>
        </Box>
      </CustomTabPanel>
      <CustomModal open={showConfirmModal}>
        {childrenModal === "VALIDATEVISIT" && (
          <ValidateDialogSegment
            type={"DOCVALIDATE"}
            title={`Estas seguro validar la visita ocular de:`}
            isProcessing={isProcessing}
            onBackClick={handleOnModalBackClicked}
            onContinueClick={handleOnModalConfirmValidateVisitClicked}
          />
        )}
        {childrenModal === "VALIDATE" && (
          <ValidateDialogSegment
            type={"DOCVALIDATE"}
            title={`Estas seguro validar el documento ${documentTriggered?.category_name} de:`}
            isProcessing={isProcessing}
            onBackClick={handleOnModalBackClicked}
            onContinueClick={handleOnModalConfirmClicked}
          />
        )}
        {childrenModal === "REJECT" && (
          <RejectDocumentDialogSegment
            document={documentTriggered}
            isProcessing={isProcessing}
            onBackClick={handleOnModalBackClicked}
            onConfirmClick={handleOnModalConfirmClicked}
          />
        )}
      </CustomModal>
    </>
  );
};
