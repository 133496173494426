import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";

import { RootState } from "@store/store";
import {
  useDeleteCommentByAppMutation,
  useLazyGetCommentsByAppQuery,
  usePatchCommentByAppMutation,
  usePostCommentByAppMutation,
} from "@services/api/applications";

import SendIcon from "@mui/icons-material/Send";
import { Comment } from "@interfaces/application";
import { calculateDatetimeDiff } from "@helpers/generals";
import { CustomPopupModal } from "./CustomPopupModal";

import EditIcon from "@assets/edit.svg";
import TrashIicon from "@assets/trash_icon.svg";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export const LoanAppComments = () => {
  const [editEnabled, setEditEnabled] = useState<boolean>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [commentsData, setCommentsData] = useState<Array<Comment>>([]);
  const [commentOptionSelected, setCommentOptionSelected] = useState<Comment>();

  const userInfo = useSelector((state: RootState) => state.app.session.user);
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const [triggerGetComments] = useLazyGetCommentsByAppQuery();
  const [triggerPostComment] = usePostCommentByAppMutation();
  const [triggerDeleteComment] = useDeleteCommentByAppMutation();
  const [triggerPatchComment] = usePatchCommentByAppMutation();

  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: yup.object().shape({
      comment: yup.string().required("El campo no puede estar vacio"),
    }),
    onSubmit: async (values) => {
      try {
        await triggerPostComment({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          payload: {
            comment: values.comment,
            user_id: userInfo.id,
          },
        }).unwrap();

        const comments = await triggerGetComments(applicationSelected.id ?? "0-0-0-0-0");
        setCommentsData(comments.data ?? []);
      } catch (error) {}
    },
  });

  const formikEdit = useFormik({
    initialValues: {
      commentEdit: "",
    },
    validationSchema: yup.object().shape({
      commentEdit: yup.string().required("El campo no puede estar vacio"),
    }),
    onSubmit: async (values) => {
      try {
        console.log(values);
        await triggerPatchComment({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          commentId: commentOptionSelected?.id ?? "0-0-0-0-0",
          payload: {
            comment: values.commentEdit,
          },
        }).unwrap();
      } catch (error) {
      } finally {
        setEditEnabled(false);

        const comments = await triggerGetComments(applicationSelected.id ?? "0-0-0-0-0");
        setCommentsData(comments.data ?? []);
      }
    },
  });

  const handleOnCommentOptionsClicked = (event: React.SyntheticEvent<HTMLElement>, comment: Comment) => {
    setCommentOptionSelected(comment);
    setAnchorEl(event.currentTarget);
  };

  const handleOnClosePopup = () => {
    setAnchorEl(null);
  };

  const handleOnEditClicked = () => {
    setAnchorEl(null);
    setEditEnabled(true);

    formikEdit.setFieldValue("commentEdit", commentOptionSelected?.comment, true);
  };

  const handleOnDeleteClicked = async () => {
    await triggerDeleteComment({
      applicationId: applicationSelected.id ?? "0-0-0-0-0",
      commentId: commentOptionSelected?.id ?? "0-0-0-0-0",
    });

    const comments = await triggerGetComments(applicationSelected.id ?? "0-0-0-0-0");
    setCommentsData(comments.data ?? []);

    setAnchorEl(null);
  };

  const handleOnCancelClicked = () => {
    setAnchorEl(null);
    setEditEnabled(false);
  };

  useEffect(() => {
    triggerGetComments(applicationSelected.id ?? "0-0-0-0-0").then((onfullfilled) => {
      setCommentsData(onfullfilled.data ?? []);
    });
  }, []);

  return (
    <>
      <Container maxWidth="xs" sx={{ mb: 3 }}>
        <Stack spacing={3}>
          {commentsData &&
            commentsData.map((comment, index) => (
              <Grid key={index} container display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <Grid item xs={1}>
                  <Avatar sx={{ bgcolor: "skyblue", width: 30, height: 30 }}>S</Avatar>
                </Grid>
                <Grid item xs={6} pl={1}>
                  <Typography variant="body1" color={"#002652"}>
                    {comment.user.name.concat(" ", comment.user.last_name, " ", comment.user.last_name_2)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" color={"#818181"} textAlign={"center"}>
                    {calculateDatetimeDiff(comment.created_at)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={(event) => handleOnCommentOptionsClicked(event, comment)}>
                    <Typography color={"#528CD6"} fontWeight={600} fontSize={20} textAlign={"right"}>
                      ...
                    </Typography>
                  </IconButton>
                </Grid>
                <Grid item xs={12} px={1}>
                  {editEnabled && commentOptionSelected?.id === comment.id ? (
                    <form onSubmit={formikEdit.handleSubmit} noValidate>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="commentEdit">Editar comentario</InputLabel>
                        <OutlinedInput
                          id="commentEdit"
                          type="text"
                          label="Editar comentario"
                          onChange={formikEdit.handleChange}
                          onBlur={formikEdit.handleBlur}
                          value={formikEdit.values.commentEdit}
                          error={formikEdit.touched.commentEdit && Boolean(formikEdit.errors.commentEdit)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                type="submit"
                                disabled={
                                  !formikEdit.dirty ||
                                  !formikEdit.isValid ||
                                  !(Object.keys(formikEdit.errors).length === 0)
                                }
                              >
                                <SendIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </form>
                  ) : (
                    <Typography variant="body1">{comment.comment}</Typography>
                  )}
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            ))}
          <form onSubmit={formik.handleSubmit} noValidate>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="comment" error={formik.touched.comment && Boolean(formik.errors.comment)}>
                Agrega un comentario
              </InputLabel>
              <OutlinedInput
                id="comment"
                type="text"
                label="Agrega un comentario"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.comment}
                error={formik.touched.comment && Boolean(formik.errors.comment)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      type="submit"
                      disabled={!formik.dirty || !formik.isValid || !(Object.keys(formik.errors).length === 0)}
                    >
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.touched.comment && Boolean(formik.errors.comment) && (
                <FormHelperText>{formik.errors.comment}</FormHelperText>
              )}
            </FormControl>
          </form>
        </Stack>
      </Container>
      <CustomPopupModal
        showClose={!editEnabled}
        anchorEl={anchorEl}
        children={
          <Box mb={2} mx={editEnabled ? 1 : 0}>
            <Grid container textAlign={"center"}>
              {editEnabled ? (
                <Grid item>
                  <IconButton onClick={handleOnCancelClicked}>
                    <CancelOutlinedIcon />
                  </IconButton>
                  <Typography>Cancelar</Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Typography>¿Que deseas hacer?</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <IconButton onClick={handleOnEditClicked}>
                      <img src={EditIcon} />
                    </IconButton>
                    <Typography>Editar</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <IconButton onClick={handleOnDeleteClicked}>
                      <img src={TrashIicon} />
                    </IconButton>
                    <Typography>Borrar</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        }
        onClose={handleOnClosePopup}
      />
    </>
  );
};
