export const formatCurrency = (amount: number, currencySymbol = "$", fixed = 2) => {
  // Formatear el número con separadores de miles y dos decimales
  // const formattedAmount = amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  // const formattedAmount = amount.toString().replace(/\d(?=(\d{3})+\.)/g, "$&,");
  // const formattedAmount = amount.toFixed(2);
  const formattedAmount = amount
    .toFixed(fixed)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Retornar el string formateado con el símbolo de la moneda
  return `${currencySymbol}${formattedAmount}`;
};
