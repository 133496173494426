import { setBpmUserLogged } from "@store/slices/appSlice";
import { baseApi } from "./baseApi";
import { BpmUser, BpmUsersResponse } from "@interfaces/users";

const usersApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCurrentUserInfo: build.query<BpmUser, string>({
            query: (q) => ({
                url: "usuarios",
                params: {
                    q
                }
            }),
            transformResponse: (response: BpmUsersResponse) => {
                return response.data.data[0];
            },
            async onQueryStarted(arg, api) {
                const {data} = await api.queryFulfilled;
                api.dispatch(setBpmUserLogged(data));
            }
        })
    })
});

export const {
    useLazyGetCurrentUserInfoQuery
} = usersApi;