import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fetchAuthSession } from "aws-amplify/auth";
// import { RootState } from '../redux/store';

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_URL_API,
    prepareHeaders: async (headers: Headers, api) => {
      // const state = getState() as RootState;

      // Este endpoint corresponde al generado del s3 y marca error al tener el header Authorization
        if (api.endpoint === "postUploadFile") {
          return headers;
        }

      const session = await fetchAuthSession();
      headers.set("authorization", `Bearer ${session.tokens?.accessToken.toString()}`);

      // headers.set(
      //   "authorization",
      //   `Bearer eyJraWQiOiJrUEIwZ3ZRbkRiaUZ3XC9PeUdkY2N2RXZiOUpGRjk1Mk5GRjZPb0VNbzBPVT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJiNDI4ODRlOC00MDgxLTcwZTItNzY0Mi0wYjY1ZmZkZmQ3ZTciLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9VQndvVEtUWWQiLCJjbGllbnRfaWQiOiI0Z3BzZGNxdm03bGlmbGt2cXNnMmw0ZzljaiIsIm9yaWdpbl9qdGkiOiI3NGUyMGY2Zi01YjJkLTRlMmEtYmEzYi0yMDBkY2ZkNGM3MDYiLCJldmVudF9pZCI6ImNmYWUxNjdhLWQzOTItNGE2ZC05ZTQ5LWM5ODI3NGI4OGZhNSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MjQ3ODkwOTQsImV4cCI6MTcyNDgwNjgwNCwiaWF0IjoxNzI0ODAzMjA0LCJqdGkiOiI3ZjRiNDQwNC0yMTNmLTRhOGYtOWM1OC0wMzg5YTVhZDc1OWIiLCJ1c2VybmFtZSI6ImI0Mjg4NGU4LTQwODEtNzBlMi03NjQyLTBiNjVmZmRmZDdlNyJ9.sFzEwENBPEhnnQNQaicj6ukEXMDl0tGEd5LGYfR1EaoLlgRMHJFof_R-5wmtIyJAC2NvC5nfSzbcX2YRqV-ROW7y8-A4IKjz1GtJqi0BFQMQ1-Fz-4f9RWK1ffCYw672XfNGXdrqvIsG2sqP0ku9yiOcgmwF0zgUPmlpxJRsd6RhPtZdqYlsdH-SuOks68sFXeeqPJ323Uhx0hZKsMOhWwtkb0KsTyHEASp1VIoyqeeqGFL14MgixGBLqtZHr4wT014AdW1YYEjjw_DmwKWkYnSUmRocb3H-wGP5t_Pn36WtiqT_72mtzCLLOGrdKc8czmxtRuV-EK9xswh2oEtzvg`,
      // );
      return headers;
    },
  }),
  keepUnusedDataFor: 30,
  endpoints: () => ({}),
});
