import { useState } from "react";
import { Box, Button, Checkbox, Grid2, IconButton, Typography } from "@mui/material";

import { UUID } from "crypto";

import { DocumentInfo } from "@interfaces/documents";

import SeeIcon from "@assets/see_icon.svg";
import RejectIcon from "@assets/reject_icon.svg";
import ValidateIcon from "@assets/validate_icon.svg";
import DownloadIcon from "@assets/download_icon.svg";
import UploadIcon from "@assets/upload_blue_icon.svg";
import RejectedIcon from "@assets/reject_enable_icon.svg";
import SeeDisabledIcon from "@assets/see_disabled_icon.svg";
import ValidatedIcon from "@assets/validate_enable_icon.svg";
import RejectDisabledIcon from "@assets/reject_disabled_icon.svg";
import ValidateDisabledIcon from "@assets/validate_disabled_icon.svg";
import DownloadDisabledIcon from "@assets/download_disabled_icon.svg";

// export interface DocumentSection extends DocumentInfo {
//   clientSignature?: boolean;
//   internalSignature?: boolean;
// }

export enum DocumentAction {
  SEE,
  DOWNLOAD,
  UPLOAD,
  VALIDATE,
  VALIDATE_TO_SIGN,
  REJECT,
  REJECT_RELOAD,
  VALIDATE_SIGN,
  REJECT_SIGN,
}

export interface InfoExtra {
  title: string;
  value: string;
}

interface FileSectionV2Props {
  title: string;
  extras?: Array<InfoExtra>;
  documents: Array<DocumentInfo> | undefined;
  showButtonGo?: boolean;
  disableButtonGo?: boolean;
  showHeaders?: boolean;
  showInternalSign?: boolean;
  showRejectSign?: boolean;
  showValidateSign?: boolean;
  showClientSign?: boolean;
  onToGoClick?: () => Promise<void>;
  onFileActionClick: (
    action: DocumentAction,
    document: DocumentInfo,
    newFile?: EventTarget & HTMLInputElement,
  ) => Promise<void>;
}

export const FileSectionV2 = ({
  title,
  extras,
  documents,
  showButtonGo = false,
  disableButtonGo = false,
  showHeaders = false,
  showInternalSign = false,
  showRejectSign = false,
  showValidateSign = false,
  showClientSign = false,
  onFileActionClick,
  onToGoClick,
  ...other
}: FileSectionV2Props) => {
  const [fileId, _] = useState<UUID>(crypto.randomUUID());
  const signMode = showInternalSign || showRejectSign || showValidateSign || showClientSign;

  const headerSegment = (child: any, visible: boolean = true) => {
    return (
      <Typography
        variant="body2"
        color="#002652"
        textAlign={"center"}
        visibility={visible && showHeaders ? "visible" : "hidden"}
      >
        {child}
      </Typography>
    );
  };

  return (
    <Box {...other}>
      <Grid2
        container
        display={"grid"}
        gridTemplateColumns={"minmax(20%, 70%) auto auto auto auto auto"}
        gridTemplateAreas={`
          "header header header header header header" "typeDoc . . . . ." "extras . . . . ." ${documents
            ?.map((_, index) => `"filename${index} icon${index} icon${index} icon${index} icon${index} icon${index}"`)
            .join(" ")}
          `}
      >
        <Grid2 gridArea={"header"} gridColumn={1}></Grid2>
        <Grid2 gridArea={"header"} gridColumn={"auto"} p={1} height={showHeaders ? "100%" : 0}>
          {headerSegment("Ver")}
        </Grid2>
        <Grid2 gridArea={"header"} gridColumn={"auto"} p={1} height={showHeaders ? "100%" : 0}>
          {headerSegment("Descargar")}
        </Grid2>
        <Grid2
          gridArea={"header"}
          gridColumn={"auto"}
          p={1}
          height={showHeaders ? "100%" : 0}
          borderRight={"1px solid #A3D4E8"}
        >
          {headerSegment("Cargar", !showButtonGo)}
        </Grid2>
        <Grid2 gridArea={"header"} gridColumn={"auto"} p={1} height={showHeaders ? "100%" : 0}>
          {headerSegment(
            signMode ? (
              <>
                Validar
                <br />
                para firma
              </>
            ) : (
              "Validar"
            ),
            !showButtonGo,
          )}
        </Grid2>
        <Grid2
          gridArea={"header"}
          gridColumn={"auto"}
          p={1}
          height={showHeaders ? "100%" : 0}
          borderRight={signMode ? "1px solid #A3D4E8" : ""}
        >
          {headerSegment(
            signMode ? (
              <>
                Rechazar
                <br />y cargar
              </>
            ) : (
              "Rechazar"
            ),
            !showButtonGo,
          )}
        </Grid2>
        <Grid2 gridArea={"header"} gridColumn={"auto"} p={1} height={showHeaders ? "100%" : 0} hidden={!showClientSign}>
          {headerSegment(
            <>
              Firma
              <br />
              Cliente
            </>,
          )}
        </Grid2>
        <Grid2
          gridArea={"header"}
          gridColumn={"auto"}
          p={1}
          height={showHeaders ? "100%" : 0}
          hidden={!showValidateSign}
        >
          {headerSegment(
            <>
              Validar
              <br />
              Firma
            </>,
          )}
        </Grid2>
        <Grid2
          gridArea={"header"}
          gridColumn={"auto"}
          p={1}
          borderRight={"1px solid #A3D4E8"}
          height={showHeaders ? "100%" : 0}
          hidden={!showRejectSign}
        >
          {headerSegment(
            <>
              Rechazar
              <br />
              Firma
            </>,
          )}
        </Grid2>
        <Grid2
          gridArea={"header"}
          gridColumn={"auto"}
          p={1}
          hidden={!showInternalSign}
          height={showHeaders ? "100%" : 0}
        >
          {headerSegment(
            <>
              Firma
              <br />
              Interna
            </>,
          )}
        </Grid2>
        <Grid2 gridArea={"typeDoc"} pb={2}>
          <Typography variant="h4" fontWeight={700} color="#002652">
            {title}
          </Typography>
        </Grid2>
        <Grid2 gridArea={"."} gridColumn={"span 3"} borderRight={"1px solid #A3D4E8"}></Grid2>
        <Grid2 gridArea={"."} gridColumn={"span 2"} borderRight={signMode ? "1px solid #A3D4E8" : ""}></Grid2>
        <Grid2 gridArea={"."} gridColumn={"span 3"} borderRight={"1px solid #A3D4E8"}></Grid2>
        <Grid2 gridArea={"."} gridColumn={"span 2"} borderRight={signMode ? "1px solid #A3D4E8" : ""}></Grid2>
        <Grid2 gridArea={"extras"}>
          {extras?.map((extra) => (
            <>
              <Typography component={"span"} variant="body1">
                {extra.title}:{" "}
              </Typography>
              <Typography component={"span"} variant="body1" fontWeight={600} color={"#528CD6"}>
                {extra.value}
              </Typography>
              <br />
            </>
          ))}
        </Grid2>
        {documents?.map((document, index) => (
          <>
            <Grid2 gridArea={`filename${index}`} borderBottom={"1px solid #A3D4E8"}>
              <Typography
                variant="body1"
                fontWeight={600}
                color="#002652"
                sx={{ whiteSpace: "nowrap", overflow: "hidden !important", textOverflow: "ellipsis" }}
              >
                Documento 1:{" "}
                <Typography component={"span"} variant="body1">
                  {document.file_name}
                </Typography>
              </Typography>
            </Grid2>
            <Grid2
              gridArea={`icon${index}`}
              gridColumn={"auto"}
              textAlign={"center"}
              borderBottom={"1px solid #A3D4E8"}
            >
              <IconButton
                disabled={document.id === undefined}
                sx={{ visibility: document ? "visible" : "hidden" }}
                onClick={() => onFileActionClick(DocumentAction.SEE, document)}
              >
                <img src={document.id === undefined ? SeeDisabledIcon : SeeIcon} />
              </IconButton>
            </Grid2>
            <Grid2
              gridArea={`icon${index}`}
              gridColumn={"auto"}
              textAlign={"center"}
              borderBottom={"1px solid #A3D4E8"}
            >
              <IconButton
                disabled={document.id === undefined}
                sx={{ pt: 1.2, visibility: document ? "visible" : "hidden" }}
                onClick={() => onFileActionClick(DocumentAction.DOWNLOAD, document)}
              >
                <img src={document.id === undefined ? DownloadDisabledIcon : DownloadIcon} />
              </IconButton>
            </Grid2>
            <Grid2
              gridArea={`icon${index}`}
              gridColumn={"auto"}
              textAlign={"center"}
              borderRight={"1px solid #A3D4E8"}
              borderBottom={"1px solid #A3D4E8"}
            >
              {showButtonGo == false && (
                <label htmlFor={`upload-file_${fileId}`}>
                  <input
                    type="file"
                    id={`upload-file_${fileId}`}
                    name={`upload-file_${fileId}`}
                    accept="application/pdf,image/jpeg,image/png"
                    hidden
                    onChange={(event) => {
                      onFileActionClick(DocumentAction.UPLOAD, document, event.target);
                    }}
                  />
                  <IconButton component={"span"} sx={{ pt: 1.2, visibility: document ? "visible" : "hidden" }}>
                    <img src={UploadIcon} />
                  </IconButton>
                </label>
              )}
            </Grid2>
            {showButtonGo ? (
              <Grid2
                gridArea={`icon${index}`}
                gridColumn={"auto"}
                sx={{ gridColumnStart: "span 2", px: 2 }}
                textAlign={"center"}
                borderBottom={"1px solid #A3D4E8"}
              >
                <Typography variant="body2" color="#002652" mt={-5.3} mb={3}>
                  Módulo
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ height: 30 }}
                  disabled={disableButtonGo}
                  onClick={() => onToGoClick?.()}
                >
                  Ir
                </Button>
              </Grid2>
            ) : (
              <>
                <Grid2
                  gridArea={`icon${index}`}
                  gridColumn={"auto"}
                  textAlign={"center"}
                  borderBottom={"1px solid #A3D4E8"}
                >
                  <IconButton
                    disabled={document.id === undefined}
                    sx={{ visibility: document ? "visible" : "hidden" }}
                    onClick={() =>
                      onFileActionClick(signMode ? DocumentAction.VALIDATE_TO_SIGN : DocumentAction.VALIDATE, document)
                    }
                  >
                    <img
                      src={
                        document.validated === true
                          ? ValidatedIcon
                          : document.validated === false || document.id === undefined
                          ? ValidateDisabledIcon
                          : ValidateIcon
                      }
                    />
                  </IconButton>
                </Grid2>
                <Grid2
                  gridArea={`icon${index}`}
                  gridColumn={"auto"}
                  textAlign={"center"}
                  borderRight={signMode ? "1px solid #A3D4E8" : ""}
                  borderBottom={"1px solid #A3D4E8"}
                >
                  {signMode ? (
                    <label htmlFor={`reupload-file_${fileId}`}>
                      <input
                        type="file"
                        id={`reupload-file_${fileId}`}
                        name={`reupload-file_${fileId}`}
                        accept="application/pdf,image/jpeg,image/png"
                        hidden
                        disabled={document.id === undefined}
                        onChange={(event) => {
                          onFileActionClick(
                            signMode ? DocumentAction.REJECT_RELOAD : DocumentAction.REJECT,
                            document,
                            event.target,
                          );
                        }}
                      />
                      <IconButton
                        disabled={document.id === undefined}
                        component={"span"}
                        sx={{ pt: 1.2, visibility: document ? "visible" : "hidden" }}
                      >
                        <img
                          src={
                            document.validated === true || document.id === undefined
                              ? RejectDisabledIcon
                              : document.validated === false
                              ? RejectedIcon
                              : RejectIcon
                          }
                        />
                      </IconButton>
                    </label>
                  ) : (
                    <IconButton
                      sx={{ visibility: document ? "visible" : "hidden" }}
                      onClick={() =>
                        onFileActionClick(signMode ? DocumentAction.REJECT_RELOAD : DocumentAction.REJECT, document)
                      }
                    >
                      <img
                        src={
                          document.validated == true || document.id === undefined
                            ? RejectDisabledIcon
                            : document.validated === false
                            ? RejectedIcon
                            : RejectIcon
                        }
                      />
                    </IconButton>
                  )}
                </Grid2>
              </>
            )}

            <Grid2
              gridArea={`icon${index}`}
              gridColumn={"auto"}
              textAlign={"center"}
              hidden={!showClientSign}
              borderBottom={"1px solid #A3D4E8"}
            >
              <Checkbox
                sx={{ pt: 0.7, pb: 0, visibility: document ? "visible" : "hidden" }}
                checked={document.sign_client ?? false}
              />
            </Grid2>
            <Grid2
              gridArea={`icon${index}`}
              gridColumn={"auto"}
              textAlign={"center"}
              hidden={!showValidateSign}
              borderBottom={"1px solid #A3D4E8"}
            >
              <IconButton
                sx={{ visibility: document ? "visible" : "hidden" }}
                disabled={!document.sign_client}
                onClick={() =>
                  onFileActionClick(signMode ? DocumentAction.VALIDATE_SIGN : DocumentAction.REJECT, document)
                }
              >
                <img
                  src={
                    document.sign_validated === true
                      ? ValidatedIcon
                      : document.sign_validated === false || document.id === undefined
                      ? ValidateDisabledIcon
                      : ValidateIcon
                  }
                />
              </IconButton>
            </Grid2>
            <Grid2
              gridArea={`icon${index}`}
              gridColumn={"auto"}
              textAlign={"center"}
              hidden={!showRejectSign}
              borderRight={"1px solid #A3D4E8"}
            >
              <IconButton
                sx={{ visibility: document ? "visible" : "hidden" }}
                disabled={!document.sign_client}
                onClick={() =>
                  onFileActionClick(signMode ? DocumentAction.REJECT_SIGN : DocumentAction.REJECT, document)
                }
              >
                <img
                  src={
                    document.sign_validated === true || document.id === undefined
                      ? RejectDisabledIcon
                      : document.sign_validated === false
                      ? RejectedIcon
                      : RejectIcon
                  }
                />
              </IconButton>
            </Grid2>
            <Grid2 gridArea={`icon${index}`} gridColumn={"auto"} textAlign={"center"} hidden={!showInternalSign}>
              <Checkbox
                sx={{ pt: 0.7, pb: 0, visibility: document ? "visible" : "hidden" }}
                checked={document.sign_user ?? false}
              />
            </Grid2>
          </>
        ))}
      </Grid2>
    </Box>
  );
};
