import { Box, Button, FormControl, Grid, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import * as yup from "yup";
import { useFormik } from "formik";
import { usePostCommentByAppMutation } from "@services/api/applications";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";

export interface MissingDocsSegmentProps {
  isProcessing?: boolean;
  onBackClick?: () => void;
  onContinueClick?: () => void;
}

export const MissingDocsSegment = (props: MissingDocsSegmentProps) => {
  const [triggerPostComment] = usePostCommentByAppMutation();

  const userInfo = useSelector((state: RootState) => state.app.session.user);
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const formik = useFormik({
    initialValues: {
      missingFiles: "",
    },
    validationSchema: yup.object().shape({
      missingFiles: yup.string().required("El campo no puede estar vacio"),
    }),
    onSubmit: async (values) => {
      triggerPostComment({
        applicationId: applicationSelected.id ?? "0-0-0-0-0",
        payload: {
          comment: values.missingFiles,
          user_id: userInfo.id,
        },
      });
      props.onContinueClick?.();
    },
  });

  return (
    <Box margin={4}>
      <Stack textAlign={"center"} spacing={2}>
        <Typography color="#002652" fontWeight={600}>
          Documentos faltantes
        </Typography>
        <form onSubmit={formik.handleSubmit} noValidate>
          <TextField
            id="missingFiles"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.missingFiles}
            error={formik.touched.missingFiles && Boolean(formik.errors.missingFiles)}
            fullWidth
            multiline
            rows={4}
            label={"Escribe cuales son los documentos faltantes"}
          />

          <Grid
            container
            flexDirection={"row"}
            gap={2}
            justifyContent={"space-around"}
            alignItems={"center"}
            marginTop={2}
          >
            <Grid item>
              <Button variant={"blue_outlined"} onClick={props.onBackClick}>
                Regresar
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                variant={"contained"}
                type="submit"
                loading={props.isProcessing}
                disabled={props.isProcessing}
              >
                Continuar
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Stack>
    </Box>
  );
};
