import { Container, Stack, Typography } from "@mui/material";
import { RootState } from "@store/store";
import { useSelector } from "react-redux";

export const LoanAppChannelInfo = () => {
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);
  
  const createRowInfo = (description: string, value: string) => {
    return (
      <Typography variant="body1" component={"span"} my={1}>
        {description}:{" "}
        <Typography variant="body1" fontWeight={600} component={"span"}>
          {value}
        </Typography>
      </Typography>
    );
  };

  return (
    <Container maxWidth="xs" sx={{ mb: 3, minWidth:'250px' }}>
      <Stack spacing={3}>
        <Typography variant="body1" textAlign={"center"} fontWeight={600} color="#528CD6">
          Canal
        </Typography>
        {createRowInfo("ID canal", applicationSelected.canalId)}
        {createRowInfo("Detalle canal", applicationSelected.asignacion)}
        {/* {createRowInfo("Director sucursal", "Francisco Pérez")} */}
        {/* {createRowInfo("Ejecutivo sucursal", "Pedro Jiménez")} */}
        {createRowInfo("Correo ejecutivo", applicationSelected.correoEjecutivo)}
        {/* {createRowInfo("Teléfono", "555 98723423")} */}
      </Stack>
    </Container>
  );
};
