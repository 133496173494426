import { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { Grid, IconButton, Popper } from "@mui/material";

import { VirtualElement } from "@floating-ui/react";

import CloseIcon from "@mui/icons-material/Close";

interface TestingProps {
  anchorEl: HTMLElement | VirtualElement | (() => HTMLElement) | (() => VirtualElement) | null | undefined;
  children: React.ReactNode;
  showClose?: boolean;
  onClose?: () => void;
}

const Arrow = styled("span")({
  position: "absolute",
  width: "3em",
  height: "3em",
  "&::before": {
    content: '""',
    margin: "auto",
    display: "block",
    width: "1em",
    height: "1em",
    borderStyle: "solid",
    transform: "translateY(-50%) rotate(45deg)",
    zIndex: "0",
    border: "0px",
    borderTop: "1px solid #528cd6",
    borderLeft: "1px solid #528cd6",
    backgroundColor: "#FFF",
  },
});

export function CustomPopupModal(props: TestingProps) {
  const [anchorEl, setAnchorEl] = useState<
    HTMLElement | VirtualElement | (() => HTMLElement) | (() => VirtualElement) | null | undefined
  >(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleOnClose = () => {
    setAnchorEl(null);
    setOpen(false);
    props.onClose?.();
  };

  useEffect(() => {
    setOpen(false);
    setAnchorEl(props.anchorEl);
    setOpen(Boolean(props.anchorEl));
  }, [props.anchorEl]);

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom"
      disablePortal={true}
      sx={{
        backgroundColor: "#FFF",
        borderRadius: 4,
        mt: "15px !important",
        filter: "drop-shadow(5px 8px 10px rgba(0,0,0,0.32))",
        border: "1px solid #528CD6",
        zIndex: 999,
      }}
      modifiers={[
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            altAxis: false,
            altBoundary: false,
            tether: true,
            rootBoundary: "viewport",
            padding: 20,
          },
        },
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      <Arrow ref={setArrowRef} />
      {props.showClose === true && (
        <Grid container display={"flex"} flexDirection={"row"}>
          <Grid item xs={12} textAlign={"right"}>
            <IconButton onClick={handleOnClose}>
              <CloseIcon sx={{ color: "#F2704F" }} />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {props.children}
    </Popper>
  );
}
