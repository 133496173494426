import { Button, Checkbox, Container, FormControlLabel, FormGroup, Skeleton, Stack, Typography } from "@mui/material";

import { UUID } from "crypto";
import { useState } from "react";

import { useGetReasonUninterestedQuery } from "@services/api/catalogs";
import { usePostLostInterestMutation, usePutApplicationsMutation } from "@services/api/applications";

export interface LoanAppLostInterestProps {
  applicationId: UUID;
  onUpdateRow?: (applicationId: UUID, attr: {}) => void;
}

export const LoanAppLostInterest = (props: LoanAppLostInterestProps) => {
  const [selected, setSelected] = useState<UUID | undefined>(undefined);

  const ReasonUninterestedResponse = useGetReasonUninterestedQuery();
  const [triggerSaveLostInterest] = usePostLostInterestMutation();
  const [triggerUpdateApplication] = usePutApplicationsMutation();

  const handleOnClickSave = async () => {
    await triggerSaveLostInterest({
      applicationId: props.applicationId,
      razon_uninterested_id: selected ? selected : "0-0-0-0-0",
    });

    await triggerUpdateApplication({
      applicationId: props.applicationId,
      body: {
        interested: false,
      },
    });

    props.onUpdateRow?.(props.applicationId, { perdioInteres: 1 });
  };

  return (
    <Container maxWidth="xs" sx={{ mb: 3 }}>
      <Stack spacing={3} textAlign={"center"} alignItems={"center"}>
        <Typography variant="body1" fontWeight={500} color="#002652">
          Selecciona por que perdió interés
        </Typography>
        <FormGroup>
          {ReasonUninterestedResponse.isSuccess ? (
            ReasonUninterestedResponse.data.map((razon, index) => (
              <FormControlLabel
                key={index}
                control={<Checkbox checked={selected === razon.id} onChange={() => setSelected(razon.id)} />}
                label={razon.name}
              />
            ))
          ) : (
            <Skeleton width={"200px"} animation={"wave"}>
              <FormControlLabel control={<Checkbox />} label="" />
            </Skeleton>
          )}
        </FormGroup>
        <Button variant="default" onClick={handleOnClickSave} disabled={selected === undefined}>
          Guardar
        </Button>
      </Stack>
    </Container>
  );
};
