import {
  Alert,
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";

import { EMAIL, PASSWORD } from "@helpers/regex";

import Logo from "@assets/lendia_white.svg";
import RightImg from "@assets/right_1.svg";
import LendiaIcon from "@assets/lendia_icon.svg";
import { CustomSwitch } from "@components/CustomSwitch";
import { fetchAuthSession, getCurrentUser, signIn, signInWithRedirect, signOut,  } from "aws-amplify/auth";

import { useLazyGetCurrentUserInfoQuery } from "@services/api/users";

export const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const [triggerGetCurrentUserInfo] = useLazyGetCurrentUserInfoQuery();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        // .notRequired()
        .required("Campo requerido")
        .matches(EMAIL, "Asegúrate de incluir el @ y los puntos necesarios (.com .mx)"),
      password: yup
        .string()
        // .notRequired()
        .required("Campo requerido")
        .matches(
          PASSWORD,
          "Formato incorrecto. Debe contener una letra mayúscula, número, carácter especial y más de 8 caracteres.",
        ),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          setLoading(true);
          setError(false);
          
          // const signedResult = await signInWithRedirect({
          //   provider: 'Amazon'
          // });

          // console.log(signedResult);

          const signedResult = await signIn({
            username: values.email,
            password: values.password,
            options: {
              authFlowType: "USER_SRP_AUTH",
            },
          });

          if (signedResult.isSignedIn && signedResult.nextStep.signInStep == "DONE") {
            const currentUser = await getCurrentUser();
            await triggerGetCurrentUserInfo(`sso_id:${currentUser.userId}`).unwrap();
            navigate("/");
          } else if (signedResult.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
            navigate("/change_password");
          }
        } catch (error) {
          setError(true);
          await signOut();
          setLoading(false);
        }
      }
    },
  });

  const handleOnChangeRememberMe = (value: React.ChangeEvent<HTMLInputElement>) => {};

  useEffect(() => {
    fetchAuthSession().then((value) => {
      if (value.tokens !== undefined) {
        navigate("/");
      }
    });
  }, []);

  return (
    <Container sx={{ height: "100vh", minWidth: "100%", p: "0 !important" }}>
      <img src={Logo} style={{ position: "absolute", marginTop: "10px", marginLeft: "10px" }}></img>
      <Grid container height={"100%"} alignItems={"center"}>
        <Grid
          container
          item
          xs={isMobileOrTablet ? 12 : 7}
          height={"100%"}
          alignItems={"center"}
          px={isMobileOrTablet ? 4 : 6}
          sx={{ backgroundColor: "#E3F2F8" }}
        >
          <Container
            sx={{
              width: "70%",
              minWidth: "420px",
              height: "50%",
              minHeight: "600px",
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
              boxShadow: "8px 4px 20px 0px #58585840",
            }}
          >
            <form onSubmit={formik.handleSubmit} noValidate>
              <Stack pt={5} direction={"column"} justifyContent={"flex-start"} alignItems={"center"}>
                <Typography variant="h2" fontWeight={700} color="#002652">
                  ¡Hola!
                </Typography>
                <Typography my={3} textAlign={"center"} variant="body1" color="#002652">
                  Ingresa tu correo y tu contraseña
                </Typography>
                <TextField
                  sx={{ my: 2, width: "60%" }}
                  id="email"
                  label="Usuario"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  value={formik.values.email}
                />
                <TextField
                  sx={{ my: 2, width: "60%" }}
                  id="password"
                  label="Contraseña"
                  type="password"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
                {error && (
                  <Box sx={{ mt: 2 }}>
                    <Alert variant="outlined" severity="error">
                      Credenciales incorrectas. Verifica tu correo electrónico y contraseña.
                    </Alert>
                  </Box>
                )}
                <Box my={3}>
                  <Link>
                    <Typography textAlign={"center"} variant="body2">
                      Olvidé mi contraseña
                    </Typography>
                  </Link>
                </Box>
                <Box textAlign={"center"} my={3}>
                  <FormControlLabel
                    control={<CustomSwitch onChange={handleOnChangeRememberMe} checked={!rememberMe} />}
                    label={"Recordar usuario en este dispositivo"}
                  />
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ py: 1, mb: 5, mt: 3, maxWidth: "60%" }}
                  //   onClick={}
                >
                  Iniciar sesión
                </Button>
              </Stack>
            </form>
          </Container>
        </Grid>
        {!isMobileOrTablet && (
          <Grid item xs={12} md={5} height={"100%"} textAlign={"center"} sx={{ backgroundColor: "#002652" }}>
            <img src={RightImg} alt="" style={{ height: "calc(100vh - 160px)" }} />
          </Grid>
        )}
      </Grid>
      <img
        src={LendiaIcon}
        style={{ position: "absolute", left: 0, bottom: 0, marginLeft: "20px", marginBottom: "20px" }}
      ></img>
    </Container>
  );
};
