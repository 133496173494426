import { styled, Tab, Tabs, TabsProps } from "@mui/material";
import { cloneElement, useState } from "react";

interface CustomTabsProps extends TabsProps {
  titleTabs: Array<{ title: string; active: boolean }>;
  alias?: string;
  onChangeTab?: (titleTab: string, index: number) => void;
}

const StyledTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  color: "#000",
  "& .Mui-selected": {
    backgroundColor: "#E3F2F8",
    color: "#000",
  },
}));

export const CustomVerticalTabPanel = ({ titleTabs, alias, onChangeTab, children, ...other }: CustomTabsProps) => {
  const [value, setValue] = useState<number>(0);

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${alias ? `${alias}-` : ""}${index}`,
      "aria-controls": `simple-tabpanel-${alias ? `${alias}-` : ""}${index}`,
    };
  };

  const handleOnChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);    
    onChangeTab?.(titleTabs[newValue].title, newValue);
  };

  return (
    <>
      <StyledTabs value={value} variant="fullWidth" onChange={handleOnChangeTab} orientation="vertical" {...other}>
        {titleTabs.map((tab, index: number) => (
          <Tab key={index} label={tab.title} {...a11yProps(index)} disabled={!tab.active}/>
        ))}
      </StyledTabs>
      {children?.map((child, index) => cloneElement(child, { value: value, index: index, key: index, alias: alias }))}
    </>
  );
};
