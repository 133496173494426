import { formatCurrency } from "@helpers/formats";
import { Typography, Divider, Grid2 } from "@mui/material";
import { useGetCompanyProfileQuery } from "@services/api/applications";
import { RootState } from "@store/store";
import { useSelector } from "react-redux";

export const GeneralTab = () => {
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const companyProfile = useGetCompanyProfileQuery(applicationSelected.rfc, {
    skip: applicationSelected.rfc === undefined,
  });

  const createRowTable = (t1: string, v1: string, t2: string, v2: string) => {
    return (
      <>
        <Grid2 size={{ xs: 6 }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
          <div>
            <Grid2 container>
              <Grid2 size={{ xs: 6 }}>
                <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                  {t1}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 6 }}>
                <Typography color={"#002652"}>{v1}</Typography>
              </Grid2>
            </Grid2>
          </div>
        </Grid2>
        <Grid2
          size={{ xs: 6 }}
          p={1}
          borderLeft={"1px solid rgba(163, 212, 232, 0.5)"}
          borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}
        >
          <div>
            <Grid2 container ml={4}>
              <Grid2 size={{ xs: 6 }}>
                <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                  {t2}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 6 }}>
                <Typography color={"#002652"}>{v2}</Typography>
              </Grid2>
            </Grid2>
          </div>
        </Grid2>
      </>
    );
  };

  const createSimpleRow = (t1: string, v1: string, cols: Array<number> = [3, 9]) => {
    return (
      <>
        <Grid2 size={{ xs: cols[0] }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
          <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
            {t1}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: cols[1] }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
          <Typography color={"#002652"}>{v1}</Typography>
        </Grid2>
      </>
    );
  };

  return (
    <>
      <Typography variant="h3" fontWeight={600} color={"#002652"}>
        Perfil Empresa
      </Typography>

      <Divider sx={{ backgroundColor: "rgba(163, 212, 232, 0.5)", my: 2 }} />

      <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
        Modelo
      </Typography>

      <Grid2 container>
        {createSimpleRow("Resultado:", companyProfile.data?.model_data.result ?? "")}
        {createSimpleRow("Calificación", companyProfile.data?.model_data.score ?? "")}
        {createSimpleRow(
          "Monto Autorizado:",
          formatCurrency(parseFloat(companyProfile.data?.model_data.authorized_amount ?? "0"), "$", 0),
        )}
        {createSimpleRow("Plan Autorizado:", `${companyProfile.data?.model_data.authorized_term ?? 0} meses`)}
      </Grid2>

      <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
        Generales empresa
      </Typography>

      <Grid2 container>
        {createRowTable(
          "Ventas Anuales Facturadas:",
          formatCurrency(parseFloat(companyProfile.data?.general_company_details.annual_sales_invoiced ?? "0"), "$", 0),
          "Compras Anuales Facturadas:",
          formatCurrency(
            parseFloat(companyProfile.data?.general_company_details.annual_revenues_invoiced ?? "0"),
            "$",
            0,
          ),
        )}
        {createRowTable(
          "Ingresos Anuales Facturadas:",
          formatCurrency(
            parseFloat(companyProfile.data?.general_company_details.annual_purchases_invoiced ?? "0"),
            "$",
            0,
          ),
          "Egresos Anuales Facturadas:",
          formatCurrency(
            parseFloat(companyProfile.data?.general_company_details.annual_expenses_invoiced ?? "0"),
            "$",
            0,
          ),
        )}
        {createRowTable(
          "Número de empleados:",
          companyProfile.data?.general_company_details.number_employees ?? "0",
          "Nómina Mensual:",
          formatCurrency(parseFloat(companyProfile.data?.general_company_details.monthly_payroll ?? "0"), "$", 0),
        )}
        {createRowTable(
          "Score Pyme (Empresa):",
          formatCurrency(parseFloat(companyProfile.data?.general_company_details.score_company ?? "0"), "$", 0),
          "BC Score (Persona):",
          formatCurrency(parseFloat(companyProfile.data?.general_company_details.bc_score ?? "0"), "$", 0),
        )}
        <Grid2 size={{ xs: 6 }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
          <div>
            <Grid2 container>
              <Grid2 size={{ xs: 6 }}>
                <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                  Antigüedad
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 6 }}>
                {/* <Typography color={"#002652"}>7 años, 2 meses</Typography> */}
                <Typography color={"#002652"}>
                  {companyProfile.data?.general_company_details.seniority ?? "0 años"}
                </Typography>
              </Grid2>
            </Grid2>
          </div>
        </Grid2>
        <Grid2 size={{ xs: 6 }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}></Grid2>
        <Grid2 size={{ xs: 6 }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
          <div>
            <Grid2 container>
              <Grid2 size={{ xs: 6 }}>
                <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                  Actividad Preponderante (%):
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 6 }}>
                <Typography color={"#002652"}>
                  {companyProfile.data?.general_company_details.predominant_activity}
                </Typography>
              </Grid2>
            </Grid2>
          </div>
        </Grid2>
        <Grid2 size={{ xs: 6 }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}></Grid2>
      </Grid2>

      <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
        Categorías de Análisis
      </Typography>
      <Grid2 container>
        {createSimpleRow(
          "Ventas, Compras, Flujos:",
          companyProfile.data?.analysis_categories.sales_purchases_flow ?? "0%",
          [4, 8],
        )}
        {createSimpleRow(
          "Clientes, Proveedores, P&S:",
          companyProfile.data?.analysis_categories.customers_suppliers_ps ?? "0%",
          [4, 8],
        )}
        {createSimpleRow(
          "Nómina y empleados:",
          companyProfile.data?.analysis_categories.payroll_employees ?? "0%",
          [4, 8],
        )}
        {createSimpleRow(
          "Financieros Declarados:",
          companyProfile.data?.analysis_categories.financial_declared ?? "0%",
          [4, 8],
        )}
        {createSimpleRow(
          "Buró Acreditado:",
          companyProfile.data?.analysis_categories.accredited_bureau ?? "0%",
          [4, 8],
        )}
        {createSimpleRow("Buró Persona:", companyProfile.data?.analysis_categories.bureau_person ?? "0%", [4, 8])}
        {createSimpleRow("CSF & Otros:", companyProfile.data?.analysis_categories.csf_others ?? "0%", [4, 8])}
        <Grid2 size={{ xs: 4 }} p={1}>
          <Typography variant="body1" color={"#002652D9"} fontWeight={700}>
            Total:
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 8 }} p={1}>
          <Typography color={"#002652D9"} fontWeight={600}>
            {companyProfile.data?.analysis_categories.total ?? "0%"}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <Divider sx={{ backgroundColor: "rgba(163, 212, 232, 0.5)", my: 1 }} />
        </Grid2>
      </Grid2>
    </>
  );
};
