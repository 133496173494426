import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "@pages/Login";
import { Dashboard } from "@pages/Dashboard";

import { MainLayout } from "./layouts/MainLayout";
import { Dictamen } from "@pages/Dictamen";
import { Expediente } from "@pages/Expediente";
import { ChangePassword } from "@pages/ChangePassword";

import "./App.css";

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />

      <Route path="/" element={<MainLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="expediente" element={<Expediente />} />
        <Route path="dictamen" element={<Dictamen />} />
        <Route path="change_password" element={<ChangePassword />} />
      </Route>
      <Route path="*" element={<Navigate to={"/login"} />} />
    </Routes>
  );
}

export default App;
