import { UUID } from "crypto";
import { baseApi } from "./baseApi";
import { AddressInfo, AddressResponse } from "@interfaces/address";

const addressApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAddress: build.query<AddressInfo, { clientDetailsId: UUID; addressTypeId: UUID }>({
      query: ({ clientDetailsId, addressTypeId }) => ({
        url: `direcciones?q=client_details_id:${clientDetailsId}&q=address_type_id:${addressTypeId}`,
        // params: {
        //   q: `client_details_id:${clientDetailsId}&q=address_type_id:${addressTypeId}`
        // q: [`client_details_id:${clientDetailsId}`, `address_type_id:${addressTypeId}`]
        // q: 
        // },
      }),
      transformResponse: (response: AddressResponse) => {
        return response.data.data[0];
      }
    }),
  }),
});

export const { useLazyGetAddressQuery, useGetAddressQuery } = addressApi;
