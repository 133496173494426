import { ReactNode, Suspense, useEffect, useState } from "react";
import { AppBar, Box, Button, Toolbar } from "@mui/material";

import { fetchAuthSession } from "aws-amplify/auth";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { RootState } from "@store/store";
import { CustomModal } from "@components/CustomModal";
import { setShowValidateFileModal } from "@store/slices/appSlice";
import { RejectApplicationDialogSegment } from "@components/RejectApplicationDialogSegment";

import logo from "@assets/lendia_full.svg";

export const MainLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [childrenModal, setChildrenModal] = useState<ReactNode | null>();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const handleOnRejectApplicationClicked = () => {
    setChildrenModal(
      <RejectApplicationDialogSegment
        onBackClick={handleOnModalBackClicked}
        onConfirmClick={handleOnModalConfirmClicked}
      />,
    );
    setShowConfirmModal(true);
  };

  const handleOnModalBackClicked = () => {
    setShowConfirmModal(false);
  };

  const handleOnModalConfirmClicked = () => {
    setShowConfirmModal(false);
  };

  useEffect(() => {
    if (["/login", "/change_password"].some((item) => item === location.pathname) === false) {
      fetchAuthSession().then((value) => {
        if (value.tokens === undefined) {
          navigate("/login");
        }
      });
    }
  }, []);

  return (
    <>
      <Box height={"100vh"}>
        <AppBar position="static" sx={{ bgcolor: "white", boxShadow: 0 }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <Box component="img" src={logo} alt="logo" sx={{ height: 25 }} />
            </Box>
            {location.pathname === "/expediente" && (
              <Box display={"flex"} flexDirection={"row"} columnGap={2}>
                <Button variant="indian_red" onClick={handleOnRejectApplicationClicked}>
                  Rechazar
                </Button>
                <Button variant="cardinal" sx={{ maxWidth: "200px", lineHeight: "20px" }}>
                  Rechazar y envíar a Listas Negras
                </Button>
              </Box>
            )}
          </Toolbar>
        </AppBar>
        <Box mx={2} height={"93vh"}>
          <Suspense fallback={<></>}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>

      <CustomModal open={showConfirmModal} children={childrenModal} />
    </>
  );
};
