import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import { useSelector } from "react-redux";

import { RootState } from "@store/store";
import { DocumentInfo } from "@interfaces/documents";
import { CustomModal } from "@components/CustomModal";
import { DocumentAction, FileSectionV2 } from "@components/FileSectionV2";
import { RejectDocumentDialogSegment } from "@components/RejectDocumentDialogSegment";
import { ValidateDialogSegment } from "@components/ValidateDialogSegment";
import { generateDummyDocuments, getMimeTypeFromFilename } from "@helpers/generals";
import { usePutApplicationsMutation } from "@services/api/applications";
import { useGetFileCategoriesQuery, useLazyGetStatusQuery } from "@services/api/catalogs";
import {
  useGetUrlToDownloadDocMutation,
  useLazyGetContractDocsQuery,
  useLazyGetSignUrlToUploadDocQuery,
  usePatchContractDocMutation,
  usePostContractDocMutation,
  usePostUploadFileMutation,
} from "@services/api/documents";

export const ContractDocsTab = () => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [childrenModal, setChildrenModal] = useState<"VALIDATE" | "REJECT">();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [documentTriggered, setDocumentTriggered] = useState<DocumentInfo>();
  const [documentActionTriggered, setDocumentActionTriggered] = useState<DocumentAction>();
  const [enableSignClientValidate, setEnableSignClientValidate] = useState<boolean>(false);
  const [enableDispersion, setEnableDispersion] = useState<boolean>(false);

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const [triggerGetDocuments, resultGetDocuments] = useLazyGetContractDocsQuery();

  const documentCategories = useGetFileCategoriesQuery();

  const [triggerGetStatus] = useLazyGetStatusQuery();
  const [triggerPostUploadDoc] = usePostUploadFileMutation();
  const [triggerPutApplication] = usePutApplicationsMutation();
  const [triggerPostContractDoc] = usePostContractDocMutation();
  const [triggerPatchContractDoc] = usePatchContractDocMutation();
  const [triggerPostUrlDocument] = useGetUrlToDownloadDocMutation();
  const [triggetPostUrlToUploadDoc] = useLazyGetSignUrlToUploadDocQuery();

  const handleOnFileActionClicked = async (
    action: DocumentAction,
    file: DocumentInfo,
    newFile?: EventTarget & HTMLInputElement,
  ) => {
    if (action === DocumentAction.SEE) {
      const urlDownload = await triggerPostUrlDocument(file.url);

      const response = await fetch(urlDownload.data ?? "");
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob], { type: getMimeTypeFromFilename(file.file_name) }));
      window.open(url);
    } else if (action === DocumentAction.DOWNLOAD) {
      const urlDownload = await triggerPostUrlDocument(file.url);
      const response = await fetch(urlDownload.data ?? "");
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("target", "_blank");
      link.setAttribute("download", file.file_name);

      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } else if (action === DocumentAction.UPLOAD) {
      const tmpFile = newFile?.files?.[0];
      try {
        if (file.id === undefined) {
          file.client_details_id = JSON.parse(applicationSelected.fullData)["client_details_id"];
        }

        const urlToUploadResponse = await triggetPostUrlToUploadDoc({
          upload_type: "client",
          file_name: tmpFile?.name ?? "",
          regimen: "pm",
          client_details_id: file.client_details_id,
          file_category_id: file.file_category_id,
          loan_application_id: applicationSelected.id,
        }).unwrap();

        const signUrl = urlToUploadResponse.data;

        const tmp = new FormData();

        tmp.append("key", signUrl.fields.key);
        tmp.append("AWSAccessKeyId", signUrl.fields.AWSAccessKeyId);
        tmp.append("x-amz-security-token", signUrl.fields["x-amz-security-token"]);
        tmp.append("policy", signUrl.fields.policy);
        tmp.append("signature", signUrl.fields.signature);
        tmp.append("file", tmpFile);

        await triggerPostUploadDoc({
          url: signUrl.url,
          body: tmp,
        }).unwrap();

        if (file.id !== undefined) {
          await triggerPatchContractDoc({
            contractDocId: file.id,
            payload: {
              url: signUrl.fields.key,
            },
          });
        } else {
          await triggerPostContractDoc({
            loan_application_id: applicationSelected.id ?? "0-0-0-0-0",
            file_category_id: file.file_category_id ?? "0-0-0-0-0",
            url: signUrl.fields.key,
            client_details_id: file.client_details_id ?? "0-0-0-0-0",
            loan_id: applicationSelected.id ?? "0-0-0-0-0",
            file_name: tmpFile?.name ?? "",
          }).unwrap();
        }
      } catch (error) {
      } finally {
        tmpFile.value = "";

        await triggerGetDocuments({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          clientDetailsId: JSON.parse(applicationSelected.fullData)["client_details_id"],
        });
      }
    } else if (action === DocumentAction.VALIDATE_TO_SIGN) {
      setDocumentActionTriggered(action);
      setDocumentTriggered(file);
      setChildrenModal("VALIDATE");
      setShowConfirmModal(true);
    } else if (action === DocumentAction.REJECT_RELOAD) {
      const tmpFile = newFile?.files?.[0];
      try {
        if (file.id === undefined) {
          file.client_details_id = JSON.parse(applicationSelected.fullData)["client_details_id"];
        }

        const urlToUploadResponse = await triggetPostUrlToUploadDoc({
          upload_type: "client",
          file_name: tmpFile?.name ?? "",
          regimen: "pm",
          client_details_id: file.client_details_id,
          file_category_id: file.file_category_id,
          loan_application_id: applicationSelected.id,
        }).unwrap();

        const signUrl = urlToUploadResponse.data;

        const tmp = new FormData();

        tmp.append("key", signUrl.fields.key);
        tmp.append("AWSAccessKeyId", signUrl.fields.AWSAccessKeyId);
        tmp.append("x-amz-security-token", signUrl.fields["x-amz-security-token"]);
        tmp.append("policy", signUrl.fields.policy);
        tmp.append("signature", signUrl.fields.signature);
        tmp.append("file", tmpFile);

        await triggerPostUploadDoc({
          url: signUrl.url,
          body: tmp,
        }).unwrap();

        await triggerPatchContractDoc({
          contractDocId: file.id ?? "0-0-0-0-0",
          payload: {
            url: signUrl.fields.key,
            file_name: tmpFile?.name ?? "",
          },
        });
      } catch (error) {
      } finally {
        tmpFile.value = "";

        await triggerGetDocuments({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          clientDetailsId: JSON.parse(applicationSelected.fullData)["client_details_id"],
        });
      }
    } else if (action === DocumentAction.VALIDATE_SIGN) {
      await triggerPatchContractDoc({
        contractDocId: file.id ?? "0-0-0-0-0",
        payload: {
          sign_validated: true,
        },
      });

      await triggerGetDocuments({
        applicationId: applicationSelected.id ?? "0-0-0-0-0",
        clientDetailsId: JSON.parse(applicationSelected.fullData)["client_details_id"],
      });
    } else if (action === DocumentAction.REJECT_SIGN) {
      await triggerPatchContractDoc({
        contractDocId: file.id ?? "0-0-0-0-0",
        payload: {
          sign_validated: false,
        },
      });

      await triggerGetDocuments({
        applicationId: applicationSelected.id ?? "0-0-0-0-0",
        clientDetailsId: JSON.parse(applicationSelected.fullData)["client_details_id"],
      });
    }
  };

  const handleOnModalBackClicked = () => {
    setShowConfirmModal(false);
  };

  const handleOnModalConfirmClicked = async () => {
    setIsProcessing(true);
    if (documentActionTriggered === DocumentAction.VALIDATE_TO_SIGN) {
      try {
        await triggerPatchContractDoc({
          contractDocId: documentTriggered?.id ?? "0-0-0-0-0",
          payload: {
            validated: true,
          },
        }).unwrap();

        await triggerGetDocuments({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          clientDetailsId: JSON.parse(applicationSelected.fullData)["client_details_id"],
        });
      } catch (error) {
        console.log("Error al validar documento: %s", JSON.stringify(error));
      }
    } else if (documentActionTriggered === DocumentAction.REJECT_RELOAD) {
    }
    setShowConfirmModal(false);
    setIsProcessing(false);
  };

  const handleOnSignClientValidateClicked = async () => {
    const statusCatalog = await triggerGetStatus().unwrap();

    await triggerPutApplication({
      applicationId: applicationSelected.id,
      body: {
        status_id: statusCatalog.data.data.find((item) => item.code === "CSFIR")?.id,
      },
    });
  };

  const handleOnDispersionClicked = async () => {
    const statusCatalog = await triggerGetStatus().unwrap();

    await triggerPutApplication({
      applicationId: applicationSelected.id,
      body: {
        status_id: statusCatalog.data.data.find((item) => item.code === "CSDIS")?.id,
      },
    });
  };

  useEffect(() => {
    triggerGetDocuments({
      applicationId: applicationSelected.id ?? "0-0-0-0-0",
      clientDetailsId: JSON.parse(applicationSelected.fullData)["client_details_id"],
    });
  }, []);

  useEffect(() => {
    if (!resultGetDocuments.isSuccess) {
      return;
    }

    setEnableSignClientValidate(
      resultGetDocuments.currentData?.length == 8 &&
        (resultGetDocuments.currentData?.every((doc) => doc.validated === true) ?? false),
    );

    setEnableDispersion(
      resultGetDocuments.currentData?.length == 8 &&
        (resultGetDocuments.currentData?.every((doc) => doc.sign_user === true) ?? false),
    );
  }, [resultGetDocuments]);

  return (
    <>
      <Box margin={3}>
        <Typography variant="h4" fontWeight={600} color={"#002652"}>
          Contractuales
        </Typography>
        <FileSectionV2
          title={"Caratula"}
          documents={
            resultGetDocuments.currentData?.some((item) => item.category_code === "CDDCA")
              ? resultGetDocuments.currentData?.filter((item) => item.category_code === "CDDCA")
              : generateDummyDocuments(
                  1,
                  documentCategories.currentData?.find((category) => category.code === "CDDCA"),
                )
          }
          onFileActionClick={handleOnFileActionClicked}
          showHeaders
          showClientSign
          showInternalSign
          showRejectSign
          showValidateSign
        />
        <FileSectionV2
          title={"Contrato"}
          documents={
            resultGetDocuments.currentData?.some((item) => item.category_code === "CDCON")
              ? resultGetDocuments.currentData?.filter((item) => item.category_code === "CDCON")
              : generateDummyDocuments(
                  1,
                  documentCategories.currentData?.find((category) => category.code === "CDCON"),
                )
          }
          onFileActionClick={handleOnFileActionClicked}
          showClientSign
          showInternalSign
          showRejectSign
          showValidateSign
        />
        <FileSectionV2
          title={"Pagaré"}
          documents={
            resultGetDocuments.currentData?.some((item) => item.category_code === "CDPAG")
              ? resultGetDocuments.currentData?.filter((item) => item.category_code === "CDPAG")
              : generateDummyDocuments(
                  1,
                  documentCategories.currentData?.find((category) => category.code === "CDPAG"),
                )
          }
          onFileActionClick={handleOnFileActionClicked}
          showClientSign
          showInternalSign
          showRejectSign
          showValidateSign
        />
        <FileSectionV2
          title={"Solicitud de disposición"}
          documents={
            resultGetDocuments.currentData?.some((item) => item.category_code === "CDSDD")
              ? resultGetDocuments.currentData?.filter((item) => item.category_code === "CDSDD")
              : generateDummyDocuments(
                  1,
                  documentCategories.currentData?.find((category) => category.code === "CDSDD"),
                )
          }
          onFileActionClick={handleOnFileActionClicked}
          showClientSign
          showInternalSign
          showRejectSign
          showValidateSign
        />
        <FileSectionV2
          title={"Tabla de amortización"}
          documents={
            resultGetDocuments.currentData?.some((item) => item.category_code === "CDTDA")
              ? resultGetDocuments.currentData?.filter((item) => item.category_code === "CDTDA")
              : generateDummyDocuments(
                  1,
                  documentCategories.currentData?.find((category) => category.code === "CDTDA"),
                )
          }
          onFileActionClick={handleOnFileActionClicked}
          showClientSign
          showInternalSign
          showRejectSign
          showValidateSign
        />
        <FileSectionV2
          title={"Autorización de domiciliación"}
          documents={
            resultGetDocuments.currentData?.some((item) => item.category_code === "CDADD")
              ? resultGetDocuments.currentData?.filter((item) => item.category_code === "CDADD")
              : generateDummyDocuments(
                  1,
                  documentCategories.currentData?.find((category) => category.code === "CDADD"),
                )
          }
          onFileActionClick={handleOnFileActionClicked}
          showClientSign
          showInternalSign
          showRejectSign
          showValidateSign
        />
        <FileSectionV2
          title={"Aval - Anexo 1"}
          documents={
            resultGetDocuments.currentData?.some((item) => item.category_code === "ANX1")
              ? resultGetDocuments.currentData?.filter((item) => item.category_code === "ANX1")
              : generateDummyDocuments(
                  1,
                  documentCategories.currentData?.find((category) => category.code === "ANX1"),
                )
          }
          onFileActionClick={handleOnFileActionClicked}
          showClientSign
          showInternalSign
          showRejectSign
          showValidateSign
        />
        <FileSectionV2
          title={"Propietario real - Anexo 2"}
          documents={
            resultGetDocuments.currentData?.some((item) => item.category_code === "ANX2")
              ? resultGetDocuments.currentData?.filter((item) => item.category_code === "ANX2")
              : generateDummyDocuments(
                  1,
                  documentCategories.currentData?.find((category) => category.code === "ANX2"),
                )
          }
          onFileActionClick={handleOnFileActionClicked}
          showClientSign
          showInternalSign
          showRejectSign
          showValidateSign
        />
      </Box>

      <Box mt={10} display={"flex"} justifyContent={"flex-end"} columnGap={2}>
        <Button variant="zomp" onClick={handleOnSignClientValidateClicked} disabled={!enableSignClientValidate}>
          Validar para firma Cliente
        </Button>
        <Button variant="zomp" onClick={handleOnDispersionClicked} disabled={!enableDispersion}>
          Liberar dispersión
        </Button>
      </Box>

      <CustomModal open={showConfirmModal}>
        {childrenModal === "VALIDATE" && (
          <ValidateDialogSegment
            type={"DOCVALIDATE"}
            title={`Estas seguro validar el documento ${documentTriggered?.category_name} de:`}
            isProcessing={isProcessing}
            onBackClick={handleOnModalBackClicked}
            onContinueClick={handleOnModalConfirmClicked}
          />
        )}
        {childrenModal === "REJECT" && (
          <RejectDocumentDialogSegment
            document={documentTriggered}
            isProcessing={isProcessing}
            onBackClick={handleOnModalBackClicked}
            onConfirmClick={handleOnModalConfirmClicked}
          />
        )}
      </CustomModal>
    </>
  );
};
