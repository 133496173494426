import {
  Alert,
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";

import { EMAIL, PASSWORD } from "@helpers/regex";

import Logo from "@assets/lendia_white.svg";
import RightImg from "@assets/right_1.svg";
import LendiaIcon from "@assets/lendia_icon.svg";
import { CustomSwitch } from "@components/CustomSwitch";
import { getCurrentUser, signIn, signOut, confirmSignIn } from "aws-amplify/auth";
import { LoadingButton } from "@mui/lab";

export const ChangePassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirm: "",
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .required("Campo requerido")
        .matches(
          PASSWORD,
          "Formato incorrecto. Debe contener una letra mayúscula, número, carácter especial y más de 8 caracteres.",
        ),
      password_confirm: yup
        .string()
        .required("Campo requerido")
        .matches(
          PASSWORD,
          "Formato incorrecto. Debe contener una letra mayúscula, número, carácter especial y más de 8 caracteres.",
        ),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          setLoading(true);
          await confirmSignIn({
            challengeResponse: values.password,
          });

          navigate("/");
        } catch (error) {
          //   setError(true);
          setLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    setError(formik.values.password !== formik.values.password_confirm);
  }, [formik.values]);

  return (
    <Container sx={{ height: "100vh", minWidth: "100%", p: "0 !important" }}>
      <img src={Logo} style={{ position: "absolute", marginTop: "10px", marginLeft: "10px" }}></img>
      <Grid container height={"100%"} alignItems={"center"}>
        <Grid
          container
          item
          xs={isMobileOrTablet ? 12 : 7}
          height={"100%"}
          alignItems={"center"}
          px={isMobileOrTablet ? 4 : 6}
          sx={{ backgroundColor: "#E3F2F8" }}
        >
          <Container
            sx={{
              width: "70%",
              minWidth: "420px",
              height: "50%",
              minHeight: "600px",
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
              boxShadow: "8px 4px 20px 0px #58585840",
            }}
          >
            <form onSubmit={formik.handleSubmit} noValidate>
              <Stack pt={5} direction={"column"} justifyContent={"flex-start"} alignItems={"center"}>
                <Typography variant="h2" fontWeight={700} color="#002652">
                  ¡Hola!
                </Typography>
                <Typography my={3} textAlign={"center"} variant="body1" color="#002652">
                  Actualiza tu contraseña
                </Typography>
                <TextField
                  sx={{ my: 2, width: "60%" }}
                  id="password"
                  label="Contraseña"
                  type="password"
                  required
                  autoComplete="new-password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  value={formik.values.password}
                />
                <TextField
                  sx={{ my: 2, width: "60%" }}
                  id="password_confirm"
                  label="Confirma tu contraseña"
                  type="password"
                  required
                  autoComplete="new-password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.password_confirm && Boolean(formik.errors.password_confirm)}
                  helperText={formik.touched.password_confirm && formik.errors.password_confirm}
                />
                {error && (
                  <Box sx={{ mt: 2 }}>
                    <Alert variant="outlined" severity="error">
                      Las contraseñas no coinciden
                    </Alert>
                  </Box>
                )}
                <LoadingButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  loading={loading}
                  disabled={loading}
                  sx={{ py: 1, mb: 5, mt: 3, maxWidth: "60%" }}
                >
                  Cambiar contraseña
                </LoadingButton>
              </Stack>
            </form>
          </Container>
        </Grid>
        {!isMobileOrTablet && (
          <Grid item xs={12} md={5} height={"100%"} textAlign={"center"} sx={{ backgroundColor: "#002652" }}>
            <img src={RightImg} alt="" style={{ height: "calc(100vh - 160px)" }} />
          </Grid>
        )}
      </Grid>
      <img
        src={LendiaIcon}
        style={{ position: "absolute", left: 0, bottom: 0, marginLeft: "20px", marginBottom: "20px" }}
      ></img>
    </Container>
  );
};
