import { UUID } from "crypto";
import { baseApi } from "./baseApi";
import { Person, PersonsResponse } from "@interfaces/persons";

const personsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPersons: build.query<Array<Person>, { q: string | undefined }>({
      query: ({ q }) => ({
        url: "personas",
        params: {
          q
        },
      }),
      transformResponse: (response: PersonsResponse) => {
        return response.data.data;
      },
    }),
    postPerson: build.mutation<any, Person>({
      query: (data) => ({
        url: "personas",
        method: "POST",
        body: data,
      }),
    }),
    patchPerson: build.mutation<any, {personId: UUID, body: unknown}>({
      query: ({personId, body}) => ({
        url: `personas/${personId}`,
        method: "PATCH",
        body,
      }),
    }),
    deletePerson: build.mutation<any, UUID | string>({
      query: (personId) => ({
        url: `personas/${personId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetPersonsQuery,
  useLazyGetPersonsQuery,
  usePostPersonMutation,
  usePatchPersonMutation,
  useDeletePersonMutation,
} = personsApi;
