import { Button, Container, Stack, Typography } from "@mui/material";
import { UUID } from "crypto";

import { usePutApplicationsMutation } from "@services/api/applications";

export interface LoanAppChangeInterestProps {
  applicationId: UUID;
  name: string;
  onUpdateRow?: (applicationId: UUID, attr: {}) => void;
}

export const LoanAppChangeInterest = (props: LoanAppChangeInterestProps) => {
  const [triggerPatch] = usePutApplicationsMutation();

  const handleOnClickConfirm = async () => {
    try {
      await triggerPatch({
        applicationId: props.applicationId,
        body: {
          interested: true,
        },
      }).unwrap();

      props.onUpdateRow?.(props.applicationId, { perdioInteres: 2 });
    } catch (error) {}
  };

  return (
    <Container maxWidth="xs" sx={{ mb: 3 }}>
      <Stack spacing={3} textAlign={"center"} alignItems={"center"}>
        <Typography variant="body1" fontWeight={500} color="#002652">
          ¿Confirmas que el cliente {props.name} recupero interés?
        </Typography>

        <Button variant="default" onClick={handleOnClickConfirm}>
          Confirmar
        </Button>
      </Stack>
    </Container>
  );
};
