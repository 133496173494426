import { Dialog, DialogProps } from "@mui/material";

export interface CustomModalProps extends DialogProps {
  open: boolean;
  // children: React.ReactNode;
}

export const CustomModal = (props: CustomModalProps) => {
  // return <Dialog open={props.open}>{props.children}</Dialog>;
  return <Dialog open={props.open}>{props.children}</Dialog>;
};
