import { formatCurrency } from "@helpers/formats";
import { ResourceUse } from "@interfaces/application";
import { Container, Divider, Grid, Stack, Typography } from "@mui/material";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
// import { useState } from "react";
import { useSelector } from "react-redux";

interface LoanApplicationInfoProps {
  // loanAppId: number;
  // fullData: string;
}

interface ApplicationData {
  applicationId: number;
  startDate: string;
  approvedAmount: number;
  approvedTerm: number;
  requestedTerm: number;
  loanAmount: number;
  creditUse: string;
}

interface UserData {
  userId: number;
  fullName: string;
  email: string;
  phone: string;
}

interface CompanyData {
  business_description: string;
  state: string;
  guarantee_name: string;
}

export const LoanApplicationInfo = (props: LoanApplicationInfoProps) => {
  const [applicationData, setApplicationData] = useState<ApplicationData>();
  const [userData, setUserData] = useState<UserData>();
  const [companyData, setCompanyData] = useState<CompanyData>({
    business_description: "-",
    guarantee_name: "-",
    state: "-",
  });

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  useEffect(() => {
    const tmp = JSON.parse(applicationSelected.fullData);

    setApplicationData({
      applicationId: tmp["id_application"],
      startDate: tmp["created_at"],
      approvedAmount: tmp["loan_application_conditions"][0]["approved_amount"],
      approvedTerm: tmp["loan_application_conditions"][0]["approved_term"],
      requestedTerm: tmp["loan_application_conditions"][0]["requested_term"],
      loanAmount: tmp["loan_application_conditions"][0]["loan_amount"],
      creditUse: tmp["loan_application_resource_use"].map((item: ResourceUse) => item.resources_use.name).join(", "),
    });

    setCompanyData({
      business_description: tmp["client_details"]["business_description"],
      state: tmp["client_details"]["address"][0] ? tmp["client_details"]["address"][0]["state"]["name"] : "-",
      guarantee_name: tmp["person"][0]
        ? [tmp["person"][0]["first_name"], tmp["person"][0]["last_name"], tmp["person"][0]["last_name_2"]].join(" ")
        : "-",
    });

    setUserData({
      userId: tmp["client_details"]["user_id"],
      fullName: [
        tmp["client_details"]["name"],
        tmp["client_details"]["last_name"],
        tmp["client_details"]["last_name_2"],
      ].join(" "),
      email: tmp["user"]["email"],
      phone: tmp["user"]["phone"],
    });
  }, [applicationSelected]);

  const createRowInfo = (description: string, value: string) => {
    return (
      <Typography variant="body1" component={"span"} my={1}>
        {description}:{" "}
        <Typography variant="body1" fontWeight={600} component={"span"}>
          {value}
        </Typography>
      </Typography>
    );
  };

  return (
    <Container maxWidth="xs" sx={{ px: "0 !important", marginRight: 2 }}>
      <Grid container display={"flex"} flexDirection={"row"}>
        <Grid item xs={1} alignContent={"center"}>
          <Typography
            variant="body1"
            fontWeight={500}
            color={"#528CD6"}
            sx={{ transform: "rotate(-90deg)", marginBottom: "-20px" }}
          >
            SOLICITUD
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Stack>
            {createRowInfo("Solicitud", applicationData?.applicationId.toString() ?? "")}
            {createRowInfo("Fecha de inicio", dayjs(applicationData?.startDate).locale("es").format("YYYY MMMM DD"))}
            {createRowInfo("Monto max. autorizado", formatCurrency(applicationData?.approvedAmount ?? 0, "$", 0))}
            {createRowInfo("Monto seleccionado", formatCurrency(applicationData?.loanAmount ?? 0, "$", 0))}
            {createRowInfo("Plazo max. autorizado", applicationData?.approvedTerm.toString() ?? "")}
            {createRowInfo("Plazo seleccionado", applicationData?.requestedTerm.toString() ?? "")}
            {createRowInfo("Uso del crédito", applicationData?.creditUse ?? "")}
          </Stack>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Divider sx={{ borderColor: "#528CD6", borderWidth: "1" }} />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1} alignContent={"center"}>
          <Typography
            variant="body1"
            fontWeight={500}
            color={"#528CD6"}
            sx={{ transform: "rotate(-90deg)", marginBottom: "-20px" }}
          >
            EMPRESA
          </Typography>
        </Grid>

        <Grid item xs={11}>
          <Stack>
            {createRowInfo("Giro", companyData?.business_description)}
            {createRowInfo("Entidad federativa", companyData?.state)}
            {createRowInfo("Aval", companyData?.guarantee_name)}
          </Stack>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Divider sx={{ borderColor: "#528CD6", borderWidth: "1" }} />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1} alignContent={"center"}>
          <Typography
            variant="body1"
            fontWeight={500}
            color={"#528CD6"}
            sx={{ transform: "rotate(-90deg)", marginBottom: "-20px" }}
          >
            USUARIO
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Stack>
            {createRowInfo("ID Usuario", userData?.userId.toString() ?? "")}
            {createRowInfo("Nombre Completo", userData?.fullName ?? "")}
            {createRowInfo("Correo", userData?.email ?? "")}
            {createRowInfo("Teléfono", userData?.phone ?? "")}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};
