import { useState } from "react";

import { LoanAppDataGrid } from "@components/LoanAppDataGrid";
import { CustomTabPanel, CustomTabs } from "@components/CustomTabs";

export const Dashboard = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);

  const handleOnChangeTab = async (_: string, indexTab: number) => {
    setCurrentTab(indexTab);
  };

  return (
    <>
      <CustomTabs
        titleTabs={["Solicitudes", "Perdieron interes", "Autorizados", "Rechazados", "Clientes"]}
        onChangeTab={handleOnChangeTab}
      ></CustomTabs>
      <CustomTabPanel index={0} value={currentTab} height={"93%"} >
        <LoanAppDataGrid
          refreshData={0 === currentTab}
          applicationType="Solicitudes"
          cols={[
            "solicitudId",
            "razonSocial",
            "rfc",
            "nombre",
            "montoSolicitado",
            "plazoSolicitado",
            "tiempoPantalla",
            "tiempoTotal",
            "etapa",
            "subEtapa",
            "estatus",
            "macroCanal",
            "canal",
            "asignacion",
            "comentarios",
            "perdioInteres",
          ]}
        />
      </CustomTabPanel>
      <CustomTabPanel index={1} value={currentTab} height={"93%"}>
        <LoanAppDataGrid
          refreshData={1 === currentTab}
          applicationType="Perdieron interes"
          cols={[
            "solicitudId",
            "razonSocial",
            "rfc",
            "nombre",
            "montoSolicitado",
            "plazoSolicitado",
            "tiempoPantalla",
            "tiempoTotal",
            "etapa",
            "subEtapa",
            "estatus",
            "macroCanal",
            "canal",
            "asignacion",
            "comentarios",
            "expediente",
            "perdioInteres",
          ]}
        />
      </CustomTabPanel>
      <CustomTabPanel index={2} value={currentTab} height={"93%"}>
        <LoanAppDataGrid
          refreshData={2 === currentTab}
          applicationType="Autorizados"
          cols={[
            "solicitudId",
            "razonSocial",
            "rfc",
            "nombre",
            "montoSolicitado",
            "plazoSolicitado",
            "tiempoPantalla",
            "tiempoTotal",
            "etapa",
            "subEtapa",
            "estatus",
            "macroCanal",
            "canal",
            "asignacion",
            "comentarios",
            "expediente",
            "perdioInteres",
          ]}
        />
      </CustomTabPanel>
      <CustomTabPanel index={3} value={currentTab} height={"93%"}>
        <LoanAppDataGrid
          refreshData={3 === currentTab}
          applicationType="Rechazados"
          cols={[
            "solicitudId",
            "razonSocial",
            "rfc",
            "nombre",
            "montoSolicitado",
            "plazoSolicitado",
            "fechaRechazo",
            "tiempoTotal",
            "etapa",
            "subEtapa",
            "macroCanal",
            "canal",
            "asignacion",
            "comentarios",
            "expediente",
          ]}
        />
      </CustomTabPanel>
      <CustomTabPanel index={4} value={currentTab} height={"93%"}>
        <LoanAppDataGrid
          refreshData={4 === currentTab}
          applicationType="Clientes"
          cols={[
            "solicitudId",
            "razonSocial",
            "rfc",
            "nombre",
            "montoContratado",
            "plazoContratado",
            "tiempoTotalProceso",
            "fechaDispersion",
            "macroCanal",
            "canal",
            "asignacion",
            "comentarios",
            "expediente",
          ]}
        />
      </CustomTabPanel>
    </>
  );
};
