import React from "react";
import ReactDOM from "react-dom/client";

import { ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";


import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { store } from "@store/store";
import App from "./App.tsx";

import { BackOfficeTheme } from "./theme/BackOfficeTheme.ts";
import { Amplify } from "aws-amplify";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_AWS_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_AWS_CLIENT_ID,
      loginWith: {
        email: true,
      },
      signUpVerificationMethod: "code",
      userAttributes: {
        email: {
          required: true,
        },
      },
    },
  },
  // Auth: {
  //   Cognito: {
  //     userPoolId: import.meta.env.VITE_AWS_USER_POOL_ID,
  //     userPoolClientId: import.meta.env.VITE_AWS_CLIENT_ID,
  //     loginWith: {
  //       oauth: {
  //           domain: 'dev-backoffice-domain.auth.us-east-1.amazoncognito.com',
  //           scopes: ['openid', 'email'],
  //           redirectSignIn: ['http://localhost:4000/dashboard'],
  //           redirectSignOut: ['http://localhost:4000/login'],
  //           responseType: 'code',
  //       }
  //     },
  //   },
  // }
});

const persistor = persistStore(store);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<></>} persistor={persistor}>
        <ThemeProvider theme={BackOfficeTheme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
