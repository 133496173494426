import { UUID } from "crypto";
import { baseApi } from "./baseApi";
import { AccountResponse } from "@interfaces/account";

const accountsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    patchAccount: build.mutation<unknown, { clientDetailsId: UUID; body: unknown }>({
      query: ({ clientDetailsId, body }) => ({
        url: `cuentas/${clientDetailsId}`,
        method: "PATCH",
        body,
      }),
    }),
    getAccount: build.query<AccountResponse, UUID>({
      query: (clientDetailsId) => ({
        url: `cuentas/${clientDetailsId}`,
      }),
    }),
  }),
});

export const { usePatchAccountMutation, useGetAccountQuery } = accountsApi;
