import { Box, Button, Grid, Stack, Typography } from "@mui/material";

import { UUID } from "crypto";
import { useSelector } from "react-redux";

import { RootState } from "@store/store";

import OkCheck from "@assets/OkCheck.svg";
import { LoadingButton } from "@mui/lab";

export interface ValidateDialogSegmentProps {
  type: "VISIT" | "COMPLETE" | "DOCVALIDATE";
  date?: string;
  time?: string;
  title: string;
  subtitle?: string;
  isProcessing?: boolean;
  onBackClick: () => void;
  onContinueClick: () => void;
}

export const ValidateDialogSegment = (props: ValidateDialogSegmentProps) => {
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  return (
    <Box margin={4}>
      <Stack textAlign={"center"} spacing={2}>
        <img src={OkCheck} height={"65px"} />
        <Typography variant="body1" fontWeight={600}>
          {props.title}
        </Typography>
        <Typography variant="body1" fontWeight={600} color="#528CD6">
          {applicationSelected.razonSocial}
        </Typography>
        <Typography variant="h4">
          ID SOLICITUD:{" "}
          <Typography component={"span"} variant="h4" fontWeight={600}>
            {applicationSelected.solicitudId}
          </Typography>
        </Typography>
        {props.type === "VISIT" && (
          <>
            <Typography variant="body1" fontWeight={400}>
              El día y la hora:
            </Typography>
            <Typography variant="body1" fontWeight={600} color="#528CD6">
              {props.date} a las {props.time}
            </Typography>
          </>
        )}

        {props.type === "COMPLETE" && <></>}

        <Grid container flexDirection={"row"} justifyContent={"space-around"} alignItems={"center"} marginTop={10}>
          <Grid item>
            <Button variant={"blue_outlined"} onClick={props.onBackClick}>
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              variant={"contained"}
              type="submit"
              loading={props.isProcessing}
              disabled={props.isProcessing}
              onClick={props.onContinueClick}
            >
              Continuar
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};
