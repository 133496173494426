import { alpha, styled } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

const ODD_OPACITY = 0.2;

export const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`&.${gridClasses.root}`]: {
    borderLeft: '1px',
    borderRight: '1px',
    borderBottom: '1px',
    borderTop: '1px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  [`& .${gridClasses.cell}`]: {
    
    whiteSpace: 'nowrap !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  [`& .${gridClasses.cell}:focus`]: {
    outline: "none",
  },
  "& ::-webkit-scrollbar": {
    width: "5px"
  },
  "& ::-webkit-scrollbar-track": {
    backgroundColor: "#D9D9D9",
  },
  "& ::-webkit-scrollbar-thumb": {
    borderRadius: "20px",
    backgroundColor: "#528CD6"
  },
  [`& .${gridClasses.columnHeader}`]: {
    backgroundColor: "#002652",
    color: "#FFF",
  },
  [`& .${gridClasses.columnHeaderTitle}`]: {
    whiteSpace: "normal",
    lineHeight: "normal",
    textAlign: "center",
  },
  [`& .${gridClasses.sortIcon}`]: {
    color: "#FFF",
  },
  [`& .${gridClasses.menuIconButton}`]: {
    color: "#FFF",
  },
  [`& .${gridClasses.filterIcon}`]: {
    color: "#FFF",
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: "#E3F2F8",
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      "&:hover": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
        },
      },
    },
  },
}));
